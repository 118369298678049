import * as React from 'react';
import { Avatar, Box, Chip, Link, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { addresses } from '../../common/constants';
import ValueDisplay from '../../components/ValueDisplay';
import bonnection from '../../common/bonnection';
import { calculateProfit, getTick, priceFromTick, priceFromTickB, Profit, TickState } from '../../common/tantalus';
import { formatUnits, parseUnits } from 'viem';
import { deepOrange, deepPurple, green, grey } from '@mui/material/colors';
import { ticksWatcher } from '../../tantalus/wheel/watch/ticks';
import { WheelInOutValues } from '../../tantalus/wheel/types';
export type TickViewProps = {
  tickId: bigint;
  overallHistory: WheelInOutValues;
}



function TickView({ tickId, overallHistory }: TickViewProps) {
  const [tickState, setTickState] = React.useState<TickState | null>(null);
  const refreshTimer = React.useRef<NodeJS.Timeout | null>(null);

  React.useEffect(() => {



    
    // schedule balance update
    const scheduleUpdate = () => {
      if (refreshTimer.current) {
        clearTimeout(refreshTimer.current);
      }
      refreshTimer.current = setTimeout(() => {
        getTick(tickId).then(setTickState).catch(() => { }).finally(() => {
          scheduleUpdate();
        });
      }, 4000);
    };


    scheduleUpdate();
    

    ticksWatcher.attach({
      listenerId: `tick-view-${tickId}`,
      tickGroupFilter: (tick) => {
        return tick === tickId;
      },
      onMatch: async (event) => {
        const t = await getTick(event.tick);
        t.amount0In += overallHistory.amount0In / 5n;
        t.amount0Out += overallHistory.amount0Out / 5n;
        t.amount1In += overallHistory.amount1In / 5n;
        t.amount1Out += overallHistory.amount1Out / 5n;
        setTickState(await getTick(event.tick));
      },
      onUnmatch: () => {
        // do nothing
      },
    });

    return () => {
      // ethereum.provider.off('block', onBlockNumber);

    }
  }, []);

  if (!tickState) {
    return <Typography>Loading...</Typography>;
  }

  const statusToColor = (status: string) => {
    switch (status) {
      case "ACTIVE":
        return green[500];
      case "DORMANT":
        return deepPurple[500];
      default:
        return grey[500];
    }
  }

  const statusToText = (status: string) => {
    switch (status) {
      case "ACTIVE":
        return "o";
      case "DORMANT":
        return "x";
      default:
        return "_";
    }
  }

  return (
    <React.Fragment>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar sx={{
            bgcolor: statusToColor(tickState.status),
            borderColor: tickState.spacesToMiddle === 0n ? deepOrange[500] : undefined,
            borderStyle: 'solid',
          }}>
            <Link href={`https://app.uniswap.org/pools/${tickState.tokenId}?chain=base`} target="_blank">
            { statusToText(tickState.status)}
            </Link>
          </Avatar>
        </ListItemAvatar>  
        <ListItemText
          primary={(
            <React.Fragment>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Typography variant="h6">
                  {formatUnits(parseUnits(priceFromTick(Number(tickState.tick)).toFixed(6), 6), 6)}
                </Typography>
              </Box>
            </React.Fragment>
          )}
          secondary={(
            <React.Fragment>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Typography variant="caption">
                  <span style={{ color: 'grey' }}>{formatUnits(tickState.amount0In - tickState.amount0Out, 18)} ETH </span> <span style={{color: 'grey'}}>{ formatUnits(tickState.amount1In - tickState.amount1Out, 6)} USDC </span> <br />
                  <span style={{ color: tickState.active0 > 0n ? 'red' : 'grey' }}> {formatUnits(tickState.active0, 18)} ETH </span> <span style={{ color: tickState.active1 > 0n ? 'green' : 'grey' }}>{ formatUnits(tickState.active1, 6)} USDC </span>
                </Typography>
                
              </Box>
            </React.Fragment>
          )}
        >
          
          </ListItemText>
    </ListItem>
      
    </React.Fragment>
  );
}

export default TickView;
