import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

// A custom theme for this app
const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },

  },
  typography: {
    body1: {
      //  fontFamily: 'Roboto',
      fontSize: '1.5rem',
      //  fontWeight: 400,
    },
    body2: {
      //  fontFamily: 'Roboto',
      fontSize: '1.5rem',
      //  fontWeight: 400,
    },
  }
});

export default theme;