import * as React from 'react';
import { Avatar, Box, Chip, Grid, Link, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { addresses } from '../common/constants';
import ValueDisplay from '../components/ValueDisplay';
import bonnection from '../common/bonnection';
import {  calculateValueOf0In1AtTick, getTick, priceFromTick, priceFromTickB, Profit, TickState, WheelInOutValues } from '../common/tantalus';
import { formatUnits, parseUnits } from 'viem';
import { deepOrange, deepPurple, green, grey } from '@mui/material/colors';
import { ticksWatcher } from '../tantalus/wheel/watch/ticks';
import { CalculatedProfit, calculateProfit, fetchWheelBalance, getCashState, getCollateralState, getConfig, getHealth, getRebalanceInfo, getRebalanceState, initWheelConfig, WheelBalance, WheelOraclePrices } from '../tantalus/wheel/actions';
import { getLivePrices } from '../tantalus/wheel/actions/prices';
import { HorizontalRule } from '@mui/icons-material';
import { fetchSeamCurrentTick, tantalusAccount } from '../common/bonnection/base';
import WheelInfo from './elements/WheelInfo';
export type SimpleViewProps = {
  
}


export type AssetsBalance = {
  ether: bigint;
  token0: bigint;
  token1: bigint;
  aToken0: bigint;
  aToken1: bigint;
  dToken0: bigint;
  dToken1: bigint;
};

export type SimpleViewState = {
  health: bigint;
  collateral: WheelInOutValues;
  cash: WheelInOutValues;
  rebalance: WheelInOutValues;
  balance: WheelBalance;
  livePrices: WheelOraclePrices;
  seamCurrentTick: bigint;
  healthThreshold: bigint;
}

function SimpleView({ }: SimpleViewProps) {
  const [state, setState] = React.useState<SimpleViewState | null>(null);
  
  const refreshTimer = React.useRef<NodeJS.Timeout | null>(null);
  const [profit, setProfit] = React.useState<CalculatedProfit | null>(null);
  const [viewBars, setViewBars] = React.useState<boolean>(false);
  const balanceRefreshTimer = React.useRef<NodeJS.Timeout | null>(null);

  React.useEffect(() => {



    
    // schedule balance update
    const scheduleBalanceUpdate = () => {
      if (balanceRefreshTimer.current) {
        clearTimeout(balanceRefreshTimer.current);
      }
      balanceRefreshTimer.current = setTimeout(() => {
        updateBalances();
      }, 2000);
    };

    // update balances
    const updateBalances = async () => {
      try {
        
        const profit = await calculateProfit() as CalculatedProfit;
        setProfit(profit);
      } catch (e) {
        console.error('updateBalances', e);
      }
      scheduleBalanceUpdate();
    };

    scheduleBalanceUpdate();
    

    const scheduleUpdate = () => {
      if (refreshTimer.current) {
        clearTimeout(refreshTimer.current);
      }
      refreshTimer.current = setTimeout(async () => {
        const health = await getHealth();
        const balance = await fetchWheelBalance();
        const cash = await getCashState();
        const rebalance = await getRebalanceState();
        const collateral = await getCollateralState();
        const livePrices = await getLivePrices();
        const config = await initWheelConfig();
        const currentSeamTick = await fetchSeamCurrentTick();
        
        setState({
          health,
          balance,
          cash,
          rebalance,
          collateral,
          livePrices,
          seamCurrentTick: currentSeamTick,
          healthThreshold: config.healthScoreThreshold!,
        });
      }, 5000);
    };


    scheduleUpdate();
    

    return () => {
      // ethereum.provider.off('block', onBlockNumber);

    }
  }, []);

  if (!state) {
    return <Typography>Loading...</Typography>;
  }

  const healthStatus = (asStr: boolean = false) => {
    //console.log('healthStatus', state.health, state.healthThreshold);
    const levels = [
      { color: green[500], label: "healthy" },
      { color: grey[500], label: "warning" },
      { color: deepOrange[500], label: "critical" },
    ]
    let response = levels[0];
    if (state && state.health) {
      if (state.health < parseUnits('1.075', 18)) {
        response= levels[2];
      } else if (state.health < state.healthThreshold) {
        response= levels[1];
      } else {
        response = levels[0];
      }
      if(asStr){
        return `${response.label} (${parseFloat(formatUnits(state.health, 18)).toFixed(2)})`;
      }
      return response;
    }
  }

  const averageUSDCRewardPer = () => {
    const totalDebt = profit?.in1.debt1!;
    const totalCollateral = profit?.in1.collateral1!;
    const avgRewardPerYearPercentage = 4_9750n;
    const totalDebt0 = profit?.in1.debt0!;
    const totalCollateral0 = profit?.in1.collateral0!;
    const avgPerYear0 = (totalCollateral0 + totalDebt0) * 2_3000n / 100_0000n;
    const avgRewardPerYear = ((totalCollateral + totalDebt) * avgRewardPerYearPercentage / 100_0000n)+ avgPerYear0;
    const avgRewardPerMinute = avgRewardPerYear / (365n * 24n * 60n);
    const avgRewardPerDay = avgRewardPerMinute * 60n * 24n;
    const avgRewardPerWeek = avgRewardPerDay * 7n;
    const avgRewardPerHour = avgRewardPerMinute * 60n;

    return {
      min: avgRewardPerMinute,
      day: avgRewardPerDay,
      week: avgRewardPerWeek,
      hour: avgRewardPerHour,
      year: avgRewardPerYear,
    }
  }

  const totalMadeSeam = () => {
    
    return state.balance.tantalus.seam + state.balance.tantalus.esSeam;
  }

  const totalMade = () => {
    const seam = totalMadeSeam();
    
    const seamIn1 = calculateValueOf0In1AtTick(Number(state.seamCurrentTick), seam);
    
    return seamIn1;
  }

  const toDisplayValue = (value: bigint, decimals: number, toFixed: number = 2) => {
    return parseFloat(formatUnits(value, decimals)).toFixed(toFixed);
  }
  const totalCollateralValue = () => {
    return profit!.in1.collateral1! + profit!.in1.collateral0;
  }
  const totalDebtValue = () => {
    return profit!.in1.debt1! + profit!.in1.debt0;
  }
  const collateralToValueRatio = () => {
    if (!profit) {
      return {
        collateralToValue: 0n,
        debtToCollateral: 0n,
        debtAndCollateralToValue: 0n,
        debt0ToCollateral0: 0n,
        debt1ToCollateral1: 0n,
        collateral0ToCollateral1: 0n,
        free0ToFree1: 0n,
        free1ToFree0: 0n,
        free0ToValue: 0n,
        free1ToValue: 0n,
        active0ToDebt0: 0n,
        active1ToDebt1: 0n,
      }
    }
    const valuein1 = profit!.totalValueIn1!;
    const collateral = profit!.in1.collateral1! + profit!.in1.collateral0;
    const debt = profit!.in1.debt1! + profit!.in1.debt0;
    const c2v = collateral * 100_0000n / valuein1;
    const d2c = debt * 100_0000n / collateral;
    const dc2v = (collateral + debt) * 100_0000n / valuein1;
    const d02c0 = profit!.in1.debt0 * 100_0000n / profit!.in1.collateral0;
    const d12c1 = profit!.in1.debt1 * 100_0000n / profit!.in1.collateral1;
    const c02c1 = profit!.in1.collateral0 * 100_0000n / profit!.in1.collateral1;
    const f02f1 = profit!.in1.free0 * 100_0000n / profit!.in1.free1;
    const f12f0 = profit!.in1.free1 * 100_0000n / profit!.in1.free0;
    
    const active02debt1Ratio = profit!.in1.debt1 > 0n ? profit!.in1.active0 * 100_0000n / profit!.in1.debt1 : 0n;
    const active12debt0Ratio = profit!.in1.debt0 > 0n ? profit!.in1.active1 * 100_0000n / profit!.in1.debt0 : 0n;

    return {
      collateralToValue: c2v,
      debtToCollateral: d2c,
      debtAndCollateralToValue: dc2v,
      debt0ToCollateral0: d02c0,
      debt1ToCollateral1: d12c1,
      collateral0ToCollateral1: c02c1,
      free0ToFree1: f02f1,
      free1ToFree0: f12f0,
      free0ToValue: profit!.in1.free0 * 100_0000n / valuein1,
      free1ToValue: profit!.in1.free1 * 100_0000n / valuein1,
      active0ToDebt0: active02debt1Ratio,
      active1ToDebt1: active12debt0Ratio,
    }
  }

  const needs1 = () => {
    const ratios = collateralToValueRatio();
    return ratios.debt1ToCollateral1 > 75_0000n;
  }

  const needs0 = () => {
    const ratios = collateralToValueRatio();
    return ratios.debt0ToCollateral0 > 75_0000n;
  }

  const hasMore0 = () => {
    const ratios = collateralToValueRatio();
    return ratios.free0ToFree1 > 100_0000n;
  }

  const hasMore1 = () => {
    const ratios = collateralToValueRatio();
    return ratios.free0ToFree1 < 100_0000n;
  }

  return (
    <React.Fragment>
      <Grid container spacing={2} padding={2}>
        <Grid item xs={12}>
          <WheelInfo
            totalMade={toDisplayValue(totalMade(), 6, 2)}
            marketPrice={toDisplayValue(state.livePrices.market0, 6, 2)}
            wheelState={(healthStatus() as any).label! as string}
            wheelStateValue={toDisplayValue(state.health, 18, 4)}
            wheelWorth={toDisplayValue(profit!.totalValueIn1!, 6, 2)}
            avgPerMin={toDisplayValue(averageUSDCRewardPer().min, 6, 4)}
            avgPerHour={toDisplayValue(averageUSDCRewardPer().hour, 6, 2)}
            avgPerDay={toDisplayValue(averageUSDCRewardPer().day, 6, 2)}
            avgPerWeek={toDisplayValue(averageUSDCRewardPer().week, 6, 2)}
            avgPerYear={toDisplayValue(averageUSDCRewardPer().year, 6, 2)}
            moneyRiding={toDisplayValue(collateralToValueRatio().debtAndCollateralToValue, 4, 2)}
            ethDebt={toDisplayValue(profit!.in1.debt0!, 6, 2)}
            ethDebtPercent={toDisplayValue(collateralToValueRatio().debt0ToCollateral0, 4, 2)}
            ethCollateral={toDisplayValue(profit!.in1.collateral0!, 6, 2)}
            usdcDebt={toDisplayValue(profit!.in1.debt1!, 6, 2)}
            usdcDebtPercent={toDisplayValue(collateralToValueRatio().debt1ToCollateral1, 4, 2)}
            usdcCollateral={toDisplayValue(profit!.in1.collateral1, 6, 2)}
            portfolioPercent={toDisplayValue(collateralToValueRatio().free1ToValue > collateralToValueRatio().free0ToValue ? collateralToValueRatio().free1ToValue : collateralToValueRatio().free0ToValue, 4, 2)}
            portfolioPercentSymbol={collateralToValueRatio().free1ToValue > collateralToValueRatio().free0ToValue ? 'USDC' : 'ETH'}
            donationsAddress='0xDa78949758f6bC2DDD7D31c5a38489096Ec7d527'
            donationsNeeded={needs0() || needs1()}
            donationsSymbol={hasMore0() ? 'USDC' : 'ETH'}
            active0={toDisplayValue(profit!.in1.active0, 6, 2)}
            active1={toDisplayValue(profit!.in1.active1, 6, 2)}
            active0Ratio={toDisplayValue(collateralToValueRatio().active0ToDebt0, 4, 2)}
            active1Ratio={toDisplayValue(collateralToValueRatio().active1ToDebt1, 4, 2)}
            totalMoneyRiding={toDisplayValue(totalCollateralValue() + totalDebtValue(), 6, 2)}
          />
          
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default SimpleView;
