
import React from 'react';
import { Typography, Box, Paper } from '@mui/material';
import { red, green, blue, orange, grey } from '@mui/material/colors';

interface WheelInfoProps {
  marketPrice: string;
  wheelState: string;
  wheelStateValue: string;
  wheelWorth: string;
  avgPerMin: string;
  avgPerHour: string;
  avgPerDay: string;
  avgPerWeek: string;
  avgPerYear: string;
  moneyRiding: string;
  totalMoneyRiding: string;
  ethDebt: string;
  ethDebtPercent: string;
  ethCollateral: string;
  usdcDebt: string;
  usdcDebtPercent: string;
  usdcCollateral: string;
  portfolioPercent: string;
  portfolioPercentSymbol: string;
  donationsNeeded: boolean;
  donationsSymbol: string;
  donationsAddress: string;
  totalMade: string;
  active0: string;
  active1: string;
  active0Ratio: string;
  active1Ratio: string;
}

const WheelInfo: React.FC<WheelInfoProps> = ({
  totalMade: totalMade,
  marketPrice,
  wheelState,
  wheelStateValue,
  wheelWorth,
  avgPerMin,
  avgPerHour,
  avgPerDay,
  avgPerWeek,
  avgPerYear,
  moneyRiding,
  ethDebt,
  ethDebtPercent,
  ethCollateral,
  usdcDebt,
  usdcDebtPercent,
  usdcCollateral,
  portfolioPercent,
  portfolioPercentSymbol,
  donationsNeeded,
  donationsSymbol,
  donationsAddress,
  active0,
  active1,
  active0Ratio,
  active1Ratio,
  totalMoneyRiding
}) => {
  return (
    <Paper elevation={3} sx={{ padding: 3, margin: 2,  }}>
      <Box>
        <Typography textAlign={"center"} variant="body1">
          The wheel is in a <span style={{ color: wheelState === "warning" ? orange[800] : (wheelState === "cricical" ? red[700] : green[700]) }}>{wheelState} ({wheelStateValue})</span> state, <br />
          that means that {wheelState === "warning" ? "debt repaying is a priority" : (wheelState === "critical" ? "restart or deposit is needed" : "wheel is accumulating collateral")}
        </Typography>

        <Typography variant="body1"  textAlign={"center"}>
          Market price is <span style={{color:blue[500]}}>${marketPrice}</span>.
        </Typography>
        <Typography textAlign={"center"} variant="body1">
        Tantalus is worth <span style={{color: green[500]}}>${wheelWorth}</span>.
        </Typography>
        
        <Typography textAlign={"center"} variant="body1" >
          Rewards EAT <span style={{color: green[700]}}>${totalMade}</span>
        </Typography>
        <Typography textAlign={"center"} variant="body1">
          Wheel is riding <span style={{color:  blue[800]}}>${totalMoneyRiding}</span> which is <span style={{color:  blue[800]}}>{moneyRiding}%</span> of the total worth.
        </Typography>
        
        
        <Typography textAlign={"center"} variant="body1" >
          It has a debt of <span style={{ color: red[800] }}>${ethDebt} ETH</span> which is <span style={{ color: red[800] }}>{ethDebtPercent}%</span> of the value of <span style={{ color: green[800] }}>${ethCollateral} ETH</span> held as collateral
           <br />
           of which <span style={{ color: green[800] }}>${active0} ETH</span> with a ratio of <span style={{ color: green[800] }}>{active0Ratio}% </span> of the wheel's USDC debt, is active in the market
        </Typography>
        <Typography textAlign={"center"} variant="body1" >
          and a debt of <span style={{ color: red[800] }}>${usdcDebt} USDC</span> which is <span style={{ color: red[800] }}>{usdcDebtPercent}%</span> of the value of <span style={{ color: green[800] }}>${usdcCollateral} USDC</span> held as collateral
          <br />
          of which <span style={{ color: green[800] }}>${active1} USDC</span> with a ratio of <span style={{ color: green[800] }}>{active1Ratio}% </span> of the wheel's ETH debt, is active in market
        </Typography>
        <Typography textAlign={"center"} variant="body1" color={blue[800]}>
          {portfolioPercent}% of the portfolio is in {portfolioPercentSymbol}
        </Typography>
        {donationsNeeded && (
          <React.Fragment>
          <Typography textAlign={"center"} variant="body1" >
          It could use some donations in {donationsSymbol}
            </Typography>
            <Typography textAlign={"center"} variant="body1" color={green[900]}>
               {donationsAddress}
            </Typography>
          </React.Fragment>
        )}
      </Box>
    </Paper>
  );
};

export default WheelInfo;