import { createPublicClient, createWalletClient, getContract, WalletClient, webSocket, zeroHash } from "viem";

import { Abi } from "viem";
import { RPC } from "../constants";

import * as chains from "viem/chains";
import abis from "../../abis";
import addresses from "../../addresses";
import { CreateListenerArgs, PublicClientType, PublicClientTypes } from "./types";


export let defaultPublicClientType: PublicClientType = PublicClientTypes.Primary;


export const publicClient = (() => {
  const _publicClient = createPublicClient({
    chain: chains.base,
    transport: webSocket(RPC)
  });
  return (_clientType: PublicClientType = defaultPublicClientType) => {

    return _publicClient as typeof _publicClient;
  }
})();


export type GasPrice = {
  baseFee: bigint;
  maxPriorityFee: bigint;
}

export const legacyGasPrice = async (priority: bigint = 200n) => {
  const gp = BigInt(await publicClient().getGasPrice()) * priority / 100n;
  return gp;

}

export const gasPrice = async (clientType: PublicClientType = defaultPublicClientType) => {
  const estimateFeesPerGas = await publicClient(clientType).estimateFeesPerGas();
  const estimatePriorityFeePerGas = await publicClient(clientType).estimateMaxPriorityFeePerGas();
  const gp: GasPrice = {
    baseFee: BigInt(estimateFeesPerGas.maxFeePerGas) * 200n / 100n,
    maxPriorityFee: BigInt(estimatePriorityFeePerGas) * 200n / 100n
  }
  return gp;
}

export const clientTypeToTransport = (clientType: PublicClientType) => {
  if (clientType === PublicClientTypes.Backup) {
    return webSocket(RPC);
  } else if (clientType === PublicClientTypes.Ref) {
    return webSocket(RPC);
  }
  return webSocket(RPC);
}

export const clientTypeToUrl = (clientType: PublicClientType) => {
  if (clientType === PublicClientTypes.Backup) {
    return RPC;
  } else if (clientType === PublicClientTypes.Ref) {
    return RPC;
  }
  return RPC;
}


export const onBlockNumber = (() => {
  const handlers = new Set<(blockNumber: bigint) => Promise<void>>();
  const backupHandlers = new Set<(blockNumber: bigint) => Promise<void>>();
  const refHandlers = new Set<(blockNumber: bigint) => Promise<void>>();
  const doHandlers = async (args: any) => {
    for (const handler of handlers) {
      try {
        await handler(args);
      }
      catch (e) {
      }
    }
  }
  const doBackupHandlers = async (args: any) => {
    for (const handler of backupHandlers) {
      try {
        await handler(args);
      }
      catch (e) {
      }
    }
  }

  const doRefHandlers = async (args: any) => {
    for (const handler of refHandlers) {
      try {
        await handler(args);
      }
      catch (e) {
      }
    }
  }

  publicClient('Primary').watchBlockNumber({
    onBlockNumber: async (blockNumber) => {
      await doHandlers(BigInt(blockNumber));
    },
    onError: (e) => {
      console.error(e)
    },
    emitOnBegin: true
  });
  publicClient('Backup').watchBlockNumber({
    onBlockNumber: async (blockNumber) => {
      await doBackupHandlers(BigInt(blockNumber));
    },
    onError: (e) => {
      console.error(e)
    },

    emitOnBegin: true
  });

  publicClient('Ref').watchBlockNumber({
    onBlockNumber: async (blockNumber) => {
      await doRefHandlers(BigInt(blockNumber));
    },
    onError: (e) => {
      console.error(e)
    },

    emitOnBegin: true
  });

  return (handler: (x: bigint) => Promise<void>, useConnection: PublicClientType = PublicClientTypes.Primary, detach: boolean = false) => {
    if (detach) {
      if (useConnection === PublicClientTypes.Backup) {
        backupHandlers.delete(handler);
      } else if (useConnection === PublicClientTypes.Ref) {
        refHandlers.delete(handler);
      } else {
        handlers.delete(handler);
      }
    } else {
      if (useConnection === PublicClientTypes.Backup) {
        backupHandlers.add(handler);
      } else if (useConnection === PublicClientTypes.Ref) {
        refHandlers.add(handler);
      } else {
        handlers.add(handler);
      }
    }
  }
})();


export type ContractDefinition = {
  abi: Abi;
  address: `0x${string}`;
}
export const defineContract = (definition: ContractDefinition) => {
  let _contract = getContract({
    abi: definition.abi,
    address: definition.address,
    client: publicClient(),
  });
  return () => {
    return _contract;
  }
}


export const state = defineContract({
  abi: abis.wheel.state,
  address: addresses.base.wheel.state,
})

export const authenticator = defineContract({
  abi: abis.wheel.authenticator,
  address: addresses.base.wheel.authenticator,
});

export const theWheel = defineContract({
  abi: abis.wheel.theWheel,
  address: addresses.base.wheel.wheel,
});

export const oracle = defineContract({
  abi: abis.wheel.oracle,
  address: addresses.base.wheel.oracle,
});

export const tickHistory = defineContract({
  abi: abis.wheel.tickHistory,
  address: addresses.base.wheel.tickHistory,
});

export const ticksPool = defineContract({
  abi: abis.wheel.ticksPool,
  address: addresses.base.wheel.ticksPool,
});

export const weth = defineContract({
  abi: abis.weth,
  address: addresses.base.tokens.weth,
});

export const usdc = defineContract({
  abi: abis.erc20,
  address: addresses.base.tokens.usdc,
});

export const seamToken = defineContract({
  abi: abis.erc20,
  address: addresses.base.seam.token,
});

export const esSeamToken = defineContract({
  abi: abis.erc20,
  address: addresses.base.seam.escrowToken,
});

export const token0 = defineContract({
  abi: abis.erc20,
  address: addresses.base.tokens.token0,
});


export const eatContract = defineContract({
  abi: abis.wheel.eat,
  address: addresses.base.wheel.eat,
});

export const distribuitor = defineContract({
  abi: abis.wheel.distribuitor,
  address: addresses.base.wheel.distribuitor,
});

export const priceToTick = defineContract({
  abi: abis.wheel.priceToTick,
  address: addresses.base.wheel.priceToTick,
});


export const tantalusAccount = () => {
  return {
    address: addresses.base.tantalus as `0x${string}`,
  }
}

export const token1 = defineContract({
  abi: abis.erc20,
  address: addresses.base.tokens.token1,
});

export const ethBalance = async (address: `0x${string}` = addresses.base.tantalus) => {
  return BigInt(await publicClient().getBalance({ address }) as bigint);
}

export const usdcBalance = async (address: `0x${string}` = addresses.base.tantalus) => {
  return BigInt(await usdc().read.balanceOf([
    address
  ]) as bigint);
}

export const usdcBalanceForAddress = async (address: `0x${string}`) => {
  return BigInt(await usdc().read.balanceOf([
    address
  ]) as bigint);
}

export const wethBalance = async (address: string = tantalusAccount().address) => {
  return BigInt(await weth().read.balanceOf([
    address as `0x${string}`
  ]) as bigint);
}


export const wethBalanceForAddress = async (address: `0x${string}`) => {
  return BigInt(await weth().read.balanceOf([
    address
  ]) as bigint);
}


export const distribuitorBalance = async () => {
  const usdc = await usdcBalanceForAddress(distribuitor().address);
  const weth = await wethBalanceForAddress(distribuitor().address);
  return {
    usdc,
    weth
  }
}



export const seamBalance = async (address: string = tantalusAccount().address) => {
  return BigInt(await seamToken().read.balanceOf([
    address as `0x${string}`
  ]) as bigint);
}

export const esSeamBalance = async (address: string = tantalusAccount().address) => {
  return BigInt(await esSeamToken().read.balanceOf([
    address as `0x${string}`
  ]) as bigint);
}


export async function fetchSeamCurrentTick(): Promise<bigint> {
  const slot0 = await uniswapSeamV3Pool().read.slot0() as bigint[];
  return BigInt(slot0[1]);
}


export const tantalusBalance = async (address: `0x${string}` = tantalusAccount().address) => {
  const eth = await ethBalance(address);
  const usdc = await usdcBalance(address);
  const weth = await wethBalance(address);
  const seam = await seamBalance(address);
  const esSeam = await esSeamBalance(address);
  return {
    eth,
    usdc,
    weth,
    seam,
    esSeam,
  }
}

export const wheelConfig = defineContract({
  abi: abis.wheel.config,
  address: addresses.base.wheel.config,
});


export const uniswapPositionManager = defineContract({
  abi: abis.uniswap.positionManager,
  address: addresses.base.uniswap.positionManager,
});

export const uniswapV3Pool = defineContract({
  abi: abis.uniswap.v3pool,
  address: addresses.base.uniswap.v3Pool,
});

export const uniswapSeamV3Pool = defineContract({
  abi: abis.uniswap.v3pool,
  address: addresses.base.uniswap.seamV3Pool,
});

export const swapper = defineContract({
  abi: abis.wheel.swapper,
  address: addresses.base.wheel.swapper,
});


export function createListener<T>(args: CreateListenerArgs,
  argPreprocessor?: (args: any) => T) {
  const handlers = new Set<(args: any) => Promise<void>>();
  const doHandlers = async (args: any) => {
    for (const handler of handlers) {
      try {
        await handler(args);
      }
      catch (e) {
      }
    }
  }

  let client = args.clientType ? publicClient(args.clientType) : publicClient();


  const onLogs = async (logs: any) => {
    if (logs.length === 0) return;
    const event = logs[0] as any;
    const processedArgs = argPreprocessor ? argPreprocessor(event.args) : event.args;
    await doHandlers(processedArgs);
  }

  const createOnLogs = () => {
    let isActive = true;
    return {
      isActive: () => {
        return isActive;
      },
      onLogs: async (logs: any) => {
        // console.log(logs);
        if (isActive) {
          await onLogs(logs);
        }
      },
      deactivate: () => {
        isActive = false;
      }
    }
  }
  let onLogsInstance = createOnLogs();

  const initEvent = (clientType: PublicClientType) => {
    onLogsInstance.deactivate();
    onLogsInstance = createOnLogs();
    client = publicClient(clientType);
    client.watchContractEvent({
      address: args.address,
      eventName: args.event,
      onLogs: onLogsInstance.onLogs,
      onError: (e) => {
        console.error(e)
      },
      abi: args.abi,
    });
  }


  initEvent(args.clientType ?? defaultPublicClientType);
  return {
    attach: (handler: (args: T) => Promise<void>) => {
      handlers.add(handler);
    },
    detach: (handler: (args: T) => Promise<void>) => {
      handlers.delete(handler);
    },
    deactivate: () => {
      onLogsInstance.deactivate();
    },
    activate: () => {
      onLogsInstance.isActive();
    },
    isActive: () => {
      return onLogsInstance.isActive();
    },
    client: () => {
      return client;
    },
    switchClient: (clientType: PublicClientType) => {
      initEvent(clientType);
    },
    address: args.address,
    event: args.event,
    abi: args.abi,

  }
}




