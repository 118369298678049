import bonnection from "../../../common/bonnection";
import { fetchSeamCurrentTick } from "../../../common/bonnection/base";
import { toLowerTickBound } from "./to-lower-tick-bound";

export async function currentTick(): Promise<bigint> {
  const result: bigint[] = await bonnection.base.ticksPool().read.currentTick() as bigint[];
  return BigInt(result[0] as bigint);
}

export async function currentTickLowerBound(): Promise<bigint> {
  return toLowerTickBound(await currentTick());
}

export async function seamCurrentTick(): Promise<bigint> {
  return await fetchSeamCurrentTick();
}

export async function seamCurrentTickLowerBound(): Promise<bigint> {
  return toLowerTickBound(await seamCurrentTick(), 60n);
}
