import { toLowerTickBound } from "./to-lower-tick-bound";

export function ticksFrame(middle: bigint, radiusLeft: bigint, radiusRight?: bigint): bigint[] {
  const spacing = 10n;
  middle = toLowerTickBound(middle);
  const ticks: bigint[] = [];
  radiusRight = radiusRight || radiusLeft;
  for (let i = middle - radiusLeft * spacing; i <= middle + radiusRight * spacing; i += spacing) {
    ticks.push(i);
  }
  return ticks;
}
