import { Chip, Tooltip } from '@mui/material';
import * as React from 'react';
import { formatUnits } from 'viem';


function ValueDisplay({
  value,
  decimals,
  decimalsDisplay,
  symbol,
  onClick,
}: { value: bigint, decimals: number, decimalsDisplay: number, symbol: string, onClick?: () => void }) {
  
  const transformedValue = parseFloat(formatUnits(value, decimals));

  if (!value) {
    value = BigInt(0);
  }
  return (
    
    <React.Fragment>
      <Tooltip title={formatUnits(value ?? 0n, decimals)}>
        <Chip style={{fontSize: "0.9em"}} onClick={onClick} label={symbol+" "+transformedValue.toFixed(decimalsDisplay)} />
      </Tooltip>
      </React.Fragment>
  );
}

export default ValueDisplay;
