import authenticator from "./authenticator";
import theWheel from "./the-wheel";
import oracle from "./oracle";
import otc from "./otc";
import tickHistory from "./tick-history";
import state from "./state";
import swapper from "./swapper";
import ticksPool from "./ticks-pool";
import config from "./config";
import distribuitor from "./distribuitor";
import eat from "./eat";
import { Abi } from "viem";
import priceToTick from "./price-to-tick";
export default {
  authenticator: authenticator as Abi,
  theWheel: theWheel as Abi,
  oracle: oracle as Abi,
  otc: otc as Abi,
  tickHistory: tickHistory as Abi,
  state: state as Abi,
  swapper: swapper as Abi,
  ticksPool: ticksPool as Abi,
  config: config as Abi,
  distribuitor: distribuitor as Abi,
  eat: eat as Abi,
  priceToTick: priceToTick as Abi,
}