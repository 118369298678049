import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';

export default function BasicMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (nextUrl: string) => {
    return () => {
      setAnchorEl(null);
      navigate(nextUrl);
    }
  }

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Dashboard
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClose("/")}>Dashboard</MenuItem>
        <MenuItem onClick={handleClose("/simple")}>Simple</MenuItem>
        <MenuItem onClick={handleClose("/numbers")}>Numbers</MenuItem>
        <MenuItem onClick={handleClose("/positions")}>Wheel Ticks</MenuItem>
        <MenuItem onClick={handleClose("/weird")}>Tantalus</MenuItem>
      </Menu>
    </div>
  );
}

