import bonnection from "../../../common/bonnection";
import { ticksFrame } from "./ticks-frame";
import { toLowerTickBound } from "./to-lower-tick-bound";
import { TickState, WheelInOutValues } from "../types";

export async function tickState(tick: bigint) {
  const result: TickState = await bonnection.base.ticksPool().read.tickState([toLowerTickBound(tick)]) as TickState;
  return {
    amount0Out: BigInt(result.amount0Out),
    amount1Out: BigInt(result.amount1Out),
    amount0In: BigInt(result.amount0In),
    amount1In: BigInt(result.amount1In),
    priceOut: BigInt(result.priceOut),
    priceIn: BigInt(result.priceIn),
    tokenId: BigInt(result.tokenId),
    active0: BigInt(result.active0),
    active1: BigInt(result.active1),
    liquidity: BigInt(result.liquidity),
    tick: BigInt(result.tick),
    spacesToMiddle: BigInt(result.spacesToMiddle),
    rawTicksToMiddle: BigInt(result.rawTicksToMiddle),
    feeGrowthInside0LastX128: BigInt(result.feeGrowthInside0LastX128),
    feeGrowthInside1LastX128: BigInt(result.feeGrowthInside1LastX128),
  } as TickState;
}

export async function overallTicksHistory(): Promise<WheelInOutValues> {

  const result = await bonnection.base.tickHistory().read.getOverall() as WheelInOutValues;
  return {
    amount0In: BigInt(result.amount0In),
    amount1In: BigInt(result.amount1In),
    amount0Out: BigInt(result.amount0Out),
    amount1Out: BigInt(result.amount1Out),
    priceIn: BigInt(result.priceIn),
    priceOut: BigInt(result.priceOut),
  } as WheelInOutValues;
}


export function frameState(middle: bigint, radiusLeft: bigint, radiusRight?: bigint): Promise<TickState[]> {
  return Promise.all(ticksFrame(middle, radiusLeft, radiusRight).map(async (tick) => {
    return await tickState(tick);
  }));
}

