import * as React from 'react';
import {  Grid, Typography } from '@mui/material';
import { calculatedBepPriceAndTick, fetchWheelBalance, getCashState, getCollateralState, getConfig, getDebtState, getHealth, getRebalanceState, getTotalOperationCost, initWheelConfig, overallTicksHistory, WheelBalance, wheelBalanceIn0, wheelBalanceIn1, WheelInOutValues, WheelOraclePrices, WheelSetConfig} from '../tantalus/wheel/actions';
import { getLivePrices } from '../tantalus/wheel/actions/prices';
import { fetchSeamCurrentTick } from '../common/bonnection/base';
import { EatStats, eatStats } from '../tantalus/wheel/actions/extreme-altruism';
import EatStatsElement from './elements/EatStats';
import Health from './elements/Health';
import BEPElement from './elements/BEP';
import PortfolioReportElement from './elements/PortfolioReport';
import PricesElement from './elements/Prices';
import VolumeElement from './elements/Volume';
import { currentTick } from '../common/tantalus';
import { DashboardViewState } from './types';
export type DashboardViewProps = {
  
}


export type AssetsBalance = {
  ether: bigint;
  token0: bigint;
  token1: bigint;
  aToken0: bigint;
  aToken1: bigint;
  dToken0: bigint;
  dToken1: bigint;
};


function DashboardView({ }: DashboardViewProps) {
  const [state, setState] = React.useState<DashboardViewState | null>(null);
  
  const stateRefreshTimer = React.useRef<NodeJS.Timeout | null>(null);

  React.useEffect(() => {



    const doUpdate = async () => {
      const health = await getHealth();
      const balance = await fetchWheelBalance();
      const balanceIn1 = await wheelBalanceIn1(balance);
      const balanceIn0 = await wheelBalanceIn0(balance);
      const cash = await getCashState();
      const debt = await getDebtState();
      const rebalance = await getRebalanceState();
      const collateral = await getCollateralState();
      const livePrices = await getLivePrices();
      const config = await initWheelConfig();
      const currentSeamTick = await fetchSeamCurrentTick();
      const ct = await currentTick();
      const es = await eatStats();
      const overall = await overallTicksHistory();
      const totalUsageCost = await getTotalOperationCost();
      const bep = await calculatedBepPriceAndTick();
      setState({
        bepTick: bep.bepTick,
        bepPrice: bep.bepPrice,
        health,
        currentTick: ct,
        eatStats: es,
        balance,
        cash,
        debt,
        rebalance,
        collateral,
        livePrices,
        seamCurrentTick: currentSeamTick,
        config: config,
        in1: {
          balance: balanceIn1
        },
        in0: {
          balance: balanceIn0
        },
        activations: overall,
        totalUsageCost: totalUsageCost
      });
    };

    const scheduleStateUpdate = () => {
      if (stateRefreshTimer.current) {
        clearTimeout(stateRefreshTimer.current);
      }
      stateRefreshTimer.current = setTimeout(async () => {
        await doUpdate();
      }, 5000);
    };


    doUpdate().then(() => {

      scheduleStateUpdate();
    });
    return () => {
      // ethereum.provider.off('block', onBlockNumber);
      if (stateRefreshTimer.current) {
        clearTimeout(stateRefreshTimer.current);
      }
    }
  }, []);

  if (!state) {
    return <Typography>Loading...</Typography>;
  }

  

  return (
    <React.Fragment>
      <Grid container spacing={2} padding={2}>
        <Grid item xs={12} md={4}>
          <Health state={state} value={state.health} threshold={state.config.healthScoreThreshold} balance={state.balance} balanceIn1={state.in1.balance} balanceIn0={state.in0.balance} />
        </Grid>
        <Grid item xs={12} md={4}>
          <PricesElement state={state} />
        </Grid>
        <Grid item xs={12} md={4}>
          <BEPElement state={state} />
        </Grid>
        <Grid item xs={12} md={4}>
          <EatStatsElement eatStats={state.eatStats} />
        </Grid>
        <Grid item xs={12} md={4}>
          <PortfolioReportElement state={state} />
        </Grid>
        <Grid item xs={12} md={4}>
          <VolumeElement state={state} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default DashboardView;
