import { formatUnits, parseUnits } from "viem";

export const MAX_PERCENTAGE = 100_0000n;

export const ONE_PERCENT = 1_0000n;
export const TWO_PERCENT = 2_0000n;
export const THREE_PERCENT = 3_0000n;
export const FOUR_PERCENT = 4_0000n;
export const FIVE_PERCENT = 5_0000n;
export const SIX_PERCENT = 6_0000n;
export const SEVEN_PERCENT = 7_0000n;
export const EIGHT_PERCENT = 8_0000n;
export const NINE_PERCENT = 9_0000n;
export const TEN_PERCENT = 10_0000n;
export const TWENTY_PERCENT = 20_0000n;
export const THIRTY_PERCENT = 30_0000n;
export const FORTY_PERCENT = 40_0000n;
export const FIFTY_PERCENT = 50_0000n;
export const SIXTY_PERCENT = 60_0000n;
export const SEVENTY_PERCENT = 70_0000n;
export const EIGHTY_PERCENT = 80_0000n;
export const NINETY_PERCENT = 90_0000n;
export const HUNDRED_PERCENT = 100_0000n;

export const ONE_MINI_PERCENT = 100n;
export const TWO_MINI_PERCENT = 200n;
export const THREE_MINI_PERCENT = 300n;
export const FOUR_MINI_PERCENT = 400n;
export const FIVE_MINI_PERCENT = 500n;
export const SIX_MINI_PERCENT = 600n;
export const SEVEN_MINI_PERCENT = 700n;
export const EIGHT_MINI_PERCENT = 800n;
export const NINE_MINI_PERCENT = 900n;
export const TEN_MINI_PERCENT = 1000n;

export const ONE_MICRO_PERCENT = 10n;
export const TWO_MICRO_PERCENT = 20n;
export const THREE_MICRO_PERCENT = 30n;
export const FOUR_MICRO_PERCENT = 40n;
export const FIVE_MICRO_PERCENT = 50n;
export const SIX_MICRO_PERCENT = 60n;
export const SEVEN_MICRO_PERCENT = 70n;
export const EIGHT_MICRO_PERCENT = 80n;
export const NINE_MICRO_PERCENT = 90n;
export const TEN_MICRO_PERCENT = 100n;



export const onePercent = (value: bigint) => value * ONE_PERCENT / HUNDRED_PERCENT;
export const twoPercent = (value: bigint) => value * TWO_PERCENT / HUNDRED_PERCENT;
export const threePercent = (value: bigint) => value * THREE_PERCENT / HUNDRED_PERCENT;
export const fourPercent = (value: bigint) => value * FOUR_PERCENT / HUNDRED_PERCENT;
export const fivePercent = (value: bigint) => value * FIVE_PERCENT / HUNDRED_PERCENT;
export const sixPercent = (value: bigint) => value * SIX_PERCENT / HUNDRED_PERCENT;
export const sevenPercent = (value: bigint) => value * SEVEN_PERCENT / HUNDRED_PERCENT;
export const eightPercent = (value: bigint) => value * EIGHT_PERCENT / HUNDRED_PERCENT;
export const ninePercent = (value: bigint) => value * NINE_PERCENT / HUNDRED_PERCENT;
export const tenPercent = (value: bigint) => value * TEN_PERCENT / HUNDRED_PERCENT;
export const twentyPercent = (value: bigint) => value * TWENTY_PERCENT / HUNDRED_PERCENT;
export const thirtyPercent = (value: bigint) => value * THIRTY_PERCENT / HUNDRED_PERCENT;
export const fortyPercent = (value: bigint) => value * FORTY_PERCENT / HUNDRED_PERCENT;
export const fiftyPercent = (value: bigint) => value * FIFTY_PERCENT / HUNDRED_PERCENT;
export const sixtyPercent = (value: bigint) => value * SIXTY_PERCENT / HUNDRED_PERCENT;
export const seventyPercent = (value: bigint) => value * SEVENTY_PERCENT / HUNDRED_PERCENT;
export const eightyPercent = (value: bigint) => value * EIGHTY_PERCENT / HUNDRED_PERCENT;
export const ninetyPercent = (value: bigint) => value * NINETY_PERCENT / HUNDRED_PERCENT;
export const hundredPercent = (value: bigint) => value;
export const percentageOf = (value: bigint, percentage: bigint) => value * percentage / HUNDRED_PERCENT;

export const oneMiniPercent = (value: bigint) => value * ONE_MINI_PERCENT / HUNDRED_PERCENT;
export const twoMiniPercent = (value: bigint) => value * TWO_MINI_PERCENT / HUNDRED_PERCENT;
export const threeMiniPercent = (value: bigint) => value * THREE_MINI_PERCENT / HUNDRED_PERCENT;
export const fourMiniPercent = (value: bigint) => value * FOUR_MINI_PERCENT / HUNDRED_PERCENT;
export const fiveMiniPercent = (value: bigint) => value * FIVE_MINI_PERCENT / HUNDRED_PERCENT;
export const sixMiniPercent = (value: bigint) => value * SIX_MINI_PERCENT / HUNDRED_PERCENT;
export const sevenMiniPercent = (value: bigint) => value * SEVEN_MINI_PERCENT / HUNDRED_PERCENT;
export const eightMiniPercent = (value: bigint) => value * EIGHT_MINI_PERCENT / HUNDRED_PERCENT;
export const nineMiniPercent = (value: bigint) => value * NINE_MINI_PERCENT / HUNDRED_PERCENT;
export const tenMiniPercent = (value: bigint) => value * TEN_MINI_PERCENT / HUNDRED_PERCENT;

export const oneMicroPercent = (value: bigint) => value * ONE_MICRO_PERCENT / HUNDRED_PERCENT;
export const twoMicroPercent = (value: bigint) => value * TWO_MICRO_PERCENT / HUNDRED_PERCENT;
export const threeMicroPercent = (value: bigint) => value * THREE_MICRO_PERCENT / HUNDRED_PERCENT;
export const fourMicroPercent = (value: bigint) => value * FOUR_MICRO_PERCENT / HUNDRED_PERCENT;
export const fiveMicroPercent = (value: bigint) => value * FIVE_MICRO_PERCENT / HUNDRED_PERCENT;
export const sixMicroPercent = (value: bigint) => value * SIX_MICRO_PERCENT / HUNDRED_PERCENT;
export const sevenMicroPercent = (value: bigint) => value * SEVEN_MICRO_PERCENT / HUNDRED_PERCENT;
export const eightMicroPercent = (value: bigint) => value * EIGHT_MICRO_PERCENT / HUNDRED_PERCENT;
export const nineMicroPercent = (value: bigint) => value * NINE_MICRO_PERCENT / HUNDRED_PERCENT;


export const percentages = {
  one: ONE_PERCENT,
  two: TWO_PERCENT,
  three: THREE_PERCENT,
  four: FOUR_PERCENT,
  five: FIVE_PERCENT,
  six: SIX_PERCENT,
  seven: SEVEN_PERCENT,
  eight: EIGHT_PERCENT,
  nine: NINE_PERCENT,
  ten: TEN_PERCENT,
  twenty: TWENTY_PERCENT,
  thirty: THIRTY_PERCENT,
  forty: FORTY_PERCENT,
  fifty: FIFTY_PERCENT,
  sixty: SIXTY_PERCENT,
  seventy: SEVENTY_PERCENT,
  eighty: EIGHTY_PERCENT,
  ninety: NINETY_PERCENT,
  hundred: HUNDRED_PERCENT,
  oneOf: onePercent,
  twoOf: twoPercent,
  threeOf: threePercent,
  fourOf: fourPercent,
  fiveOf: fivePercent,
  sixOf: sixPercent,
  sevenOf: sevenPercent,
  eightOf: eightPercent,
  nineOf: ninePercent,
  tenOf: tenPercent,
  twentyOf: twentyPercent,
  thirtyOf: thirtyPercent,
  fortyOf: fortyPercent,
  fiftyOf: fiftyPercent,
  sixtyOf: sixtyPercent,
  seventyOf: seventyPercent,
  eightyOf: eightyPercent,
  ninetyOf: ninetyPercent,
  hundredOf: hundredPercent,
  micro: {
    one: ONE_MICRO_PERCENT,
    two: TWO_MICRO_PERCENT,
    three: THREE_MICRO_PERCENT,
    four: FOUR_MICRO_PERCENT,
    five: FIVE_MICRO_PERCENT,
    six: SIX_MICRO_PERCENT,
    seven: SEVEN_MICRO_PERCENT,
    eight: EIGHT_MICRO_PERCENT,
    nine: NINE_MICRO_PERCENT,
    oneOf: oneMicroPercent,
    twoOf: twoMicroPercent,
    threeOf: threeMicroPercent,
    fourOf: fourMicroPercent,
    fiveOf: fiveMicroPercent,
    sixOf: sixMicroPercent,
    sevenOf: sevenMicroPercent,
    eightOf: eightMicroPercent,
    nineOf: nineMicroPercent,
  },
  mini: {
    one: ONE_MINI_PERCENT,
    two: TWO_MINI_PERCENT,
    three: THREE_MINI_PERCENT,
    four: FOUR_MINI_PERCENT,
    five: FIVE_MINI_PERCENT,
    six: SIX_MINI_PERCENT,
    seven: SEVEN_MINI_PERCENT,
    eight: EIGHT_MINI_PERCENT,
    nine: NINE_MINI_PERCENT,
    ten: TEN_MINI_PERCENT,
    oneOf: oneMiniPercent,
    twoOf: twoMiniPercent,
    threeOf: threeMiniPercent,
    fourOf: fourMiniPercent,
    fiveOf: fiveMiniPercent,
    sixOf: sixMiniPercent,
    sevenOf: sevenMiniPercent,
    eightOf: eightMiniPercent,
    nineOf: nineMiniPercent,
  }
}

export const maxCap = (value: bigint, max: bigint) => value > max ? max : value;
export const minCap = (value: bigint, min: bigint) => value < min ? min : value;

export function priceFromTick(tick: number): number {
  // =(POW(1.0001, A2) / (pow(10, 6-18)))
  return Math.pow(1.0001, tick) / Math.pow(10, 6 - 18);
}

export function priceFromTickB(tick: number): bigint {
  // =(POW(1.0001, A2) / (pow(10, 6-18)))
  return parseUnits(`${Math.pow(1.0001, tick) / Math.pow(10, 6 - 18)}`, 6);
}

export function calculateValueOf0In1AtTick(tick: number, amount0: bigint): bigint {
  const price = priceFromTick(tick);
  const amount1 = Number(formatUnits(amount0, 18)) * price;
  return parseUnits(amount1.toString(), 6);
}

export function calculateValueOf1In0AtTick(tick: number, amount1: bigint): bigint {
  const price = priceFromTick(tick);
  const amount0 = Number(formatUnits(amount1, 6)) / price;
  return parseUnits(amount0.toString(), 18);
}

