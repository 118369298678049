import * as React from 'react';
import ValueDisplay from '../ValueDisplay';
import { ListItem } from '@mui/material';
export type ValueEitherOrProps = {
  eth: bigint;
  usdc: bigint;
  asItem?: boolean;
  prefix?: string;
  ethSymbol?: string;
  usdcSymbol?: string;
  side?: 'eth' | 'usdc';
}

export function ValueEitherOr({ eth, usdc , asItem, prefix, ethSymbol, usdcSymbol, side}: ValueEitherOrProps) {
  

  const [showEthValue, setShowEthValue] = React.useState<boolean>(side === undefined || side === 'eth');

  const switchDisplayedValue = () => {
    setShowEthValue(!showEthValue);
  }

  let ethSymbolToUse = ethSymbol === undefined ? 'ETH' : ethSymbol;
  let usdcSymbolToUse = usdcSymbol === undefined ? 'USDC' : usdcSymbol;
  
  ethSymbolToUse = prefix ? `${prefix}${ethSymbolToUse}` : ethSymbolToUse;
  usdcSymbolToUse = prefix ? `${prefix}${usdcSymbolToUse}` : usdcSymbolToUse;

  const viewComponent = (showEthValue ? <ValueDisplay onClick={switchDisplayedValue} value={eth} decimals={18} decimalsDisplay={8} symbol={ethSymbolToUse} />
    : <ValueDisplay value={usdc} onClick={switchDisplayedValue} decimals={6} decimalsDisplay={4} symbol={usdcSymbolToUse} />)
  
  if (asItem) {
    return (<ListItem>{viewComponent}</ListItem>);
  } else {
    return viewComponent;
  }
}

