import { Abi } from "viem";

export type PublicClientType = 'Primary' | 'Backup' | 'Ref';

export const PublicClientTypes = {
  Primary: 'Primary' as PublicClientType,
  Backup: 'Backup' as PublicClientType,
  Ref: 'Ref' as PublicClientType,
};


export type CreateListenerArgs = {
  address: `0x${string}`;
  event: string;
  abi: Abi;
  clientType?: PublicClientType;
  followDefault?: boolean;
}
