import wheel from "./wheel";
import aave from "./aave";
import erc20 from "./erc20";
import erc721 from "./erc721";
import weth from "./weth";
import uniswap from "./uniswap";
import priceToTick from "./wheel/price-to-tick";
import { Abi } from "viem";

export default {
  wheel,
  aave,
  erc20: erc20 as Abi,
  erc721: erc721 as Abi,
  weth: weth as Abi,
  uniswap,
}