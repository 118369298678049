import { formatUnits } from "viem";

export const toDisplayValue = (value: bigint, decimals: number, toFixed: number = 2) => {
  return parseFloat(formatUnits(value, decimals)).toFixed(toFixed);
}

export const ethDisplayValue = (value: bigint, toFixed: number = 2) => {
  return toDisplayValue(value, 18, toFixed);
}

export const usdDisplayValue = (value: bigint, toFixed: number = 2) => {
  return toDisplayValue(value, 6, toFixed);
}
export const percentageDisplayValue = (value: bigint, toFixed: number = 2) => {
  return toDisplayValue(value, 4, toFixed) + '%';
}


