
import React from 'react';
import { Typography, Box, Paper, Card, CardContent, Link, Popper } from '@mui/material';
import { red, green, blue, orange, grey } from '@mui/material/colors';
import { formatUnits, parseUnits } from 'viem';
import { ethDisplayValue, usdDisplayValue } from '../../transform/to-number-display';
import { EatStats } from '../../tantalus/wheel/actions/extreme-altruism';
import { toAddressDisplay, toAddressExplorerLink } from '../../transform/to-string-display';
import { WheelBalance } from '../../tantalus/wheel/actions';
import { DashboardViewState } from '../types';

interface ElementProps {
  state: DashboardViewState;
}

const Element: React.FC<ElementProps> = ({
  state
}) => {

  const { cash, rebalance, activations, debt, collateral, balance, totalUsageCost, bepTick, bepPrice } = state;

  const amount0In = activations.amount0In + rebalance.amount0In - totalUsageCost / 2n;
  const amount1In = activations.amount1In + rebalance.amount1In;
  const amount0Out = activations.amount0Out + rebalance.amount0Out + totalUsageCost / 2n;
  const amount1Out = activations.amount1Out + rebalance.amount1Out;

  const amount0 = amount0In - amount0Out;
  const amount1 = amount1In - amount1Out;

  const sellBepPrice: bigint = -1n * (amount0 != 0n && amount1 < 0n ? amount1 * parseUnits("1", 18) / amount0 : 0n);
  const buyBepPrice: bigint = amount0 != 0n && amount1 > 0n ? amount1 * parseUnits("1", 18) / amount0 : 0n;

  return (
    <Card elevation={3} sx={{ padding: 3, margin: 2,  }}>
      <CardContent>
      <Typography variant="h6" textAlign={"justify"}>Market</Typography>
        <Typography variant="h5" textAlign={"justify"}> ${usdDisplayValue(state.livePrices.market0)}</Typography>
<br />
        <Typography variant="h6" textAlign={"justify"}>Collateral</Typography>
        <Typography variant="h5" textAlign={"justify"}>${usdDisplayValue(state.livePrices.collateral0)}</Typography>
        <br />
        <Typography variant="h6" textAlign={"justify"}>BEP</Typography>
        <Typography variant="h5" style={{ color: red[100] }} textAlign={"justify"}>${usdDisplayValue(bepPrice)}</Typography>
        
      </CardContent>
    </Card>
  );
};

export default Element;