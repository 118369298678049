import * as React from 'react';
import './App.css';
import {  Grid } from '@mui/material';
import Observations from './components/Observations';
import QuickView from './components/QuickView';
import TicksView from './components/TicksView';
import SimpleView from './components/SimpleView';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import BasicMenu from './components/elements/Menu';
import DashboardView from './components/Dashboard';
export type AppProps = {}

function App({ }: AppProps) {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const navigate = useNavigate();

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleNavigation = (path: string) => {
    navigate(path);
    setDrawerOpen(false);
  };

  return (
    <React.Fragment>
      
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <BasicMenu />
        </Grid>
        <Grid item xs={12}>
        <Routes>
            <Route path="/" element={<DashboardView />} />
            <Route path="/simple" element={<SimpleView />} />
          <Route path="/numbers" element={<QuickView />} />
          <Route path="/observations" element={<Observations />} />
          <Route path="/positions" element={<TicksView />} />
        </Routes>
          </Grid>
      </Grid>
      
       
      
    </React.Fragment>
  );
}

export default function AppWithRouter() {
  return (
    <Router>
      <App />
    </Router>
  );
}