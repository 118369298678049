import { oracle } from "../../../common/bonnection/base";
import { WheelOraclePrices } from "../types";

export const getLivePrices = async (): Promise<WheelOraclePrices> => {

  const pricesRaw: WheelOraclePrices = await oracle().read.livePrices() as WheelOraclePrices;
  return {
    collateral0: BigInt(pricesRaw.collateral0),
    collateral1: BigInt(pricesRaw.collateral1),
    market0: BigInt(pricesRaw.market0),
    market1: BigInt(pricesRaw.market1),
  } as WheelOraclePrices;
}
