
import React from 'react';
import { Typography, Box, Paper, Card, CardContent, Link, Popper } from '@mui/material';
import { red, green, blue, orange, grey } from '@mui/material/colors';
import { formatUnits, parseUnits } from 'viem';
import { ethDisplayValue, usdDisplayValue } from '../../transform/to-number-display';
import { EatStats } from '../../tantalus/wheel/actions/extreme-altruism';
import { toAddressDisplay, toAddressExplorerLink } from '../../transform/to-string-display';
import { WheelBalance } from '../../tantalus/wheel/actions';
import { DashboardViewState } from '../types';

interface ElementProps {
  state: DashboardViewState;
}

const Element: React.FC<ElementProps> = ({
  state
}) => {

  const { cash, rebalance, activations, debt, collateral, balance } = state;

  return (
    <Card elevation={3} sx={{ padding: 3, margin: 2,  }}>
      <CardContent>
       
      <Typography variant="h6" textAlign={"justify"}>Cash</Typography>
      <Typography variant="h5" textAlign={"justify"}>ETH</Typography>
        <Typography variant="body1" textAlign={"justify"}>
          In: {ethDisplayValue(cash.amount0In, 8)}<br />
          Out: {ethDisplayValue(cash.amount0Out, 8)}
        </Typography>
        <Typography variant="h5" textAlign={"justify"}>USDC</Typography>
        <Typography variant="body1" textAlign={"justify"}>
          In: {usdDisplayValue(cash.amount1In)}<br />
          Out: {usdDisplayValue(cash.amount1Out)}
        </Typography>
        <br />
        <Typography variant="h6" textAlign={"justify"}>Collateral</Typography>
        <Typography variant="h5" textAlign={"justify"}>ETH</Typography>
        <Typography variant="body1" textAlign={"justify"}>
          In: {ethDisplayValue(collateral.amount0In, 8)}<br />
          Out: {ethDisplayValue(collateral.amount0Out, 8)}
        </Typography>
        <Typography variant="h5" textAlign={"justify"}>USDC</Typography>
        <Typography variant="body1" textAlign={"justify"}>
          In: {usdDisplayValue(collateral.amount1In)}<br />
          Out: {usdDisplayValue(collateral.amount1Out)}
        </Typography>
        <br />
        <Typography variant="h6" textAlign={"justify"}>Debt</Typography>
        <Typography variant="h5" textAlign={"justify"}>ETH</Typography>
        <Typography variant="body1" textAlign={"justify"}>
          In: {ethDisplayValue(debt.amount0In, 8)}<br />
          Out: {ethDisplayValue(debt.amount0Out, 8)}
        </Typography>
        <Typography variant="h5" textAlign={"justify"}>USDC</Typography>
        <Typography variant="body1" textAlign={"justify"}>
          In: {usdDisplayValue(debt.amount1In)}<br />
          Out: {usdDisplayValue(debt.amount1Out)}
        </Typography>
        <br />
        <Typography variant="h6" textAlign={"justify"}>Rebalance</Typography>
        <Typography variant="h5" textAlign={"justify"}>ETH</Typography>
        <Typography variant="body1" textAlign={"justify"}>
          In: {ethDisplayValue(rebalance.amount0In, 8)}<br />
          Out: {ethDisplayValue(rebalance.amount0Out, 8)}
        </Typography>
        <Typography variant="h5" textAlign={"justify"}>USDC</Typography>
        <Typography variant="body1" textAlign={"justify"}>
          In: {usdDisplayValue(rebalance.amount1In)}<br />
          Out: {usdDisplayValue(rebalance.amount1Out)}
        </Typography>
        <br />
        <Typography variant="h6" textAlign={"justify"}>Activation</Typography>
        <Typography variant="h5" textAlign={"justify"}>ETH</Typography>
        <Typography variant="body1" textAlign={"justify"}>
          In: {ethDisplayValue(activations.amount0In, 8)}<br />
          Out: {ethDisplayValue(activations.amount0Out, 8)}
        </Typography>
        <Typography variant="h5" textAlign={"justify"}>USDC</Typography>
        <Typography variant="body1" textAlign={"justify"}>
          In: {usdDisplayValue(activations.amount1In)}<br />
          Out: {usdDisplayValue(activations.amount1Out)}
        </Typography>
        


      </CardContent>
    </Card>
  );
};

export default Element;