
import React from 'react';
import { Typography, Box, Paper, Card, CardContent, Link, Popper } from '@mui/material';
import { red, green, blue, orange, grey } from '@mui/material/colors';
import { formatUnits, parseUnits } from 'viem';
import { ethDisplayValue, usdDisplayValue } from '../../transform/to-number-display';
import { EatStats } from '../../tantalus/wheel/actions/extreme-altruism';
import { toAddressDisplay, toAddressExplorerLink } from '../../transform/to-string-display';
import { WheelBalance } from '../../tantalus/wheel/actions';
import { DashboardViewState } from '../types';

interface ElementProps {
  state: DashboardViewState;
  
}

const Element: React.FC<ElementProps> = ({
  state
}) => {
  const balance = state.balance;
  const balanceIn1 = state.in1.balance;
  const balanceIn0 = state.in0.balance;
  const active0ToDebt0 = balance.d0 > 0 ? (balance.active.token0 * 100_0000n) / balance.d0 : 0n;
  const active1ToDebt1 = balance.d1 > 0 ? (balance.active.token1 * 100_0000n) / balance.d1 : 0n;

  const active0ToDebt1 = balanceIn0.d1 > 0 ? (balanceIn0.active.token0 * 100_0000n) / balanceIn0.d1 : 0n;
  const active1ToDebt0 = balanceIn0.d0 > 0 ? (balanceIn0.active.token1 * 100_0000n) / balanceIn0.d0 : 0n;
  const free0 = balanceIn0.a0 - balanceIn0.d0 + balanceIn0.active.token0;
  const free1 = balanceIn1.a1 - balanceIn1.d1 + balanceIn1.active.token1;
  return (
    <Card elevation={3} sx={{ padding: 3, margin: 2,  }}>
      <CardContent>
        <Typography variant="h5" textAlign="justify">ETH {ethDisplayValue(free0, 8)}</Typography>
        <Typography variant="body1" textAlign="justify">
          cETH {ethDisplayValue(balance.a0, 8)} <br />
          dETH {ethDisplayValue(balance.d0, 8)} <br />
          aETH {ethDisplayValue(balance.active.token0, 8)}
        </Typography>
        <br />
        <Typography variant="h5" textAlign="justify">USDC {usdDisplayValue(free1)}</Typography>
        <Typography variant="body1" textAlign={"justify"}>
          
          cUSDC {usdDisplayValue(balance.a1)} <br />
          dUSDC {usdDisplayValue(balance.d1)} <br />
          aUSDC {usdDisplayValue(balance.active.token1)}
          <br />

        </Typography>
        <Typography variant="h5" textAlign={"center"}></Typography>

      </CardContent>
    </Card>
  );
};

export default Element;