import { tickHistory } from "../../../common/bonnection/base";
import { WheelInOutValues } from "../types";
import { currentTick } from "./current-tick";
import { ticksFrame } from "./ticks-frame";
import { toLowerTickBound } from "./to-lower-tick-bound";

export type ActiveStats = {
  active0In: bigint;
  active0Out: bigint;
  active1In: bigint;
  active1Out: bigint;
  over0In: bigint;
  over0Out: bigint;
  over1In: bigint;
  over1Out: bigint;
}
let lastComputedAt = 0;
let lastComputed: WheelInOutValues = {
  amount0In: 0n,
  amount1In: 0n,
  amount0Out: 0n,
  amount1Out: 0n,
  priceOut: 0n,
  priceIn: 0n

};
export const getActiveStats = async () => {
  if (Date.now() - lastComputedAt < 10_000) {
    return lastComputed;
  }

  lastComputedAt = Date.now();

  const lastComputedR = await tickHistory().read.getOverall() as WheelInOutValues;
  lastComputed = {
    amount0In: BigInt(lastComputedR.amount0In),
    amount1In: BigInt(lastComputedR.amount1In),
    amount0Out: BigInt(lastComputedR.amount0Out),
    amount1Out: BigInt(lastComputedR.amount1Out),
    priceOut: BigInt(lastComputedR.priceOut),
    priceIn: BigInt(lastComputedR.priceIn)
  }


  return lastComputed;
}