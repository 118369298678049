/*

WHEEL_ADDRESS=0x81548eB86a497c105Af10273486933EA332e3e70
WHEEL_AUTHENTICATOR_ADDRESS=0xD1ef62fB00009Ed5731102E9F749C04B87089e61
WHEEL_CONFIG_ADDRESS=0xa86C7cAF87f8Ca42fAa593AC08fB10Df2B76Bb91
WHEEL_SWAPPER_ADDRESS=0xE52b4432F133A446f2eA6F7451052Afe9E2833B6
WHEEL_TICK_HISTORY_ADDRESS=0x7207297BB7A1b9432299Ed4158fC6B527201d6d7
WHEEL_STATE_ADDRESS=0x7A8513D6a6c66C42935741eA42BB7eD14be51bC5
WHEEL_TICKS_POOL_ADDRESS=0x5E3eF1633E650287B2F538325228e55389069606
WHEEL_ORACLE_ADDRESS=0xc3216376Cb2f085480dA3FBd0D971e5A6dB44B5C



export const wheel = {
  wheel: '0x81548eB86a497c105Af10273486933EA332e3e70' as `0x${string}`,
  authenticator: '0xD1ef62fB00009Ed5731102E9F749C04B87089e61' as `0x${string}`,
  config: '0xa86C7cAF87f8Ca42fAa593AC08fB10Df2B76Bb91' as `0x${string}`,
  swapper: '0xE52b4432F133A446f2eA6F7451052Afe9E2833B6' as `0x${string}`,
  tickHistory: '0x7207297BB7A1b9432299Ed4158fC6B527201d6d7' as `0x${string}`,
  state: '0x7A8513D6a6c66C42935741eA42BB7eD14be51bC5' as `0x${string}`,
  ticksPool: '0x5E3eF1633E650287B2F538325228e55389069606' as `0x${string}`,
  oracle: '0xc3216376Cb2f085480dA3FBd0D971e5A6dB44B5C' as `0x${string}`
};


export const aave = {
  pool: '',
  addressProvider: '0xe20fCBdBfFC4Dd138cE8b2E6FBb6CB49777ad64D' as `0x${string}`
}


export const tokens = {
  usdc: '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913' as `0x${string}`,
  weth: '0x4200000000000000000000000000000000000006' as `0x${string}`,
  token1: '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913' as `0x${string}`,
  token0: '0x4200000000000000000000000000000000000006' as `0x${string}`,
  aToken0: '0xD4a0e0b9149BCee3C920d2E00b5dE09138fd8bb7' as `0x${string}`,
  aToken1: '0x4e65fE4DbA92790696d040ac24Aa414708F5c0AB' as `0x${string}`,
}



export const uniswap = {
  positionManager: '0x03a520b32C04BF3bEEf7BEb72E919cf822Ed34f1' as `0x${string}`,
  poolFactory: '0x33128a8fC17869897dcE68Ed026d694621f6FDfD' as `0x${string}`,
  v3Pool: '0xd0b53D9277642d899DF5C87A3966A349A798F224' as `0x${string}`
}




*/

/*

WHEEL_ADDRESS=0x81548eB86a497c105Af10273486933EA332e3e70
WHEEL_AUTHENTICATOR_ADDRESS=0xD1ef62fB00009Ed5731102E9F749C04B87089e61
WHEEL_CONFIG_ADDRESS=0xa86C7cAF87f8Ca42fAa593AC08fB10Df2B76Bb91
WHEEL_SWAPPER_ADDRESS=0xE52b4432F133A446f2eA6F7451052Afe9E2833B6
WHEEL_TICK_HISTORY_ADDRESS=0x7207297BB7A1b9432299Ed4158fC6B527201d6d7
WHEEL_STATE_ADDRESS=0x7A8513D6a6c66C42935741eA42BB7eD14be51bC5
WHEEL_TICKS_POOL_ADDRESS=0x5E3eF1633E650287B2F538325228e55389069606
WHEEL_ORACLE_ADDRESS=0xc3216376Cb2f085480dA3FBd0D971e5A6dB44B5C

WHEEL_SPIN_OPERATOR=0x1C7AD1C530654f17B82eBAF6022028B808F76252


WHEEL_ADDRESS=0x6115680Fef9e905131E58555d5E7972a2bf3EeE2
WHEEL_AUTHENTICATOR_ADDRESS=0xC1147AeAC66730437140bc371b8191FA8F46889F
WHEEL_CONFIG_ADDRESS=0x3EA16d734CeE8DA57e3bd508eC930168b61d2D04
WHEEL_SWAPPER_ADDRESS=0xC63f58fE64A8Aa5483ac80aC041320161352e3e1
WHEEL_TICK_HISTORY_ADDRESS=0xF47C87AcddA6B4e18Dd7f467CDB10A37F8893f63
WHEEL_STATE_ADDRESS=0x16690C437740e5490bCe453bD5f91D44Ef819316
WHEEL_TICKS_POOL_ADDRESS=0xfC69C3F0Aa5C623F3470aa61Db8d799c2AE0CD38
WHEEL_ORACLE_ADDRESS=0x7c453BeF4328FD7dd1bA0aa0b4BAb3A3b86F8F60


export const wheel = {
  wheel: '0x81548eB86a497c105Af10273486933EA332e3e70' as `0x${string}`,
  authenticator: '0xD1ef62fB00009Ed5731102E9F749C04B87089e61' as `0x${string}`,
  config: '0xa86C7cAF87f8Ca42fAa593AC08fB10Df2B76Bb91' as `0x${string}`,
  swapper: '0xE52b4432F133A446f2eA6F7451052Afe9E2833B6' as `0x${string}`,
  tickHistory: '0x7207297BB7A1b9432299Ed4158fC6B527201d6d7' as `0x${string}`,
  state: '0x7A8513D6a6c66C42935741eA42BB7eD14be51bC5' as `0x${string}`,
  ticksPool: '0x5E3eF1633E650287B2F538325228e55389069606' as `0x${string}`,
  oracle: '0xc3216376Cb2f085480dA3FBd0D971e5A6dB44B5C' as `0x${string}`,
};



*/

/*

WHEEL_ADDRESS=0x81548eB86a497c105Af10273486933EA332e3e70
WHEEL_AUTHENTICATOR_ADDRESS=0xD1ef62fB00009Ed5731102E9F749C04B87089e61
WHEEL_CONFIG_ADDRESS=0xa86C7cAF87f8Ca42fAa593AC08fB10Df2B76Bb91
WHEEL_SWAPPER_ADDRESS=0xE52b4432F133A446f2eA6F7451052Afe9E2833B6
WHEEL_TICK_HISTORY_ADDRESS=0x7207297BB7A1b9432299Ed4158fC6B527201d6d7
WHEEL_STATE_ADDRESS=0x7A8513D6a6c66C42935741eA42BB7eD14be51bC5
WHEEL_TICKS_POOL_ADDRESS=0x5E3eF1633E650287B2F538325228e55389069606
WHEEL_ORACLE_ADDRESS=0xc3216376Cb2f085480dA3FBd0D971e5A6dB44B5C

WHEEL_SPIN_OPERATOR=0x1C7AD1C530654f17B82eBAF6022028B808F76252


WHEEL_ADDRESS=0x6115680Fef9e905131E58555d5E7972a2bf3EeE2
WHEEL_AUTHENTICATOR_ADDRESS=0xC1147AeAC66730437140bc371b8191FA8F46889F
WHEEL_CONFIG_ADDRESS=0x3EA16d734CeE8DA57e3bd508eC930168b61d2D04
WHEEL_SWAPPER_ADDRESS=0xC63f58fE64A8Aa5483ac80aC041320161352e3e1
WHEEL_TICK_HISTORY_ADDRESS=0xF47C87AcddA6B4e18Dd7f467CDB10A37F8893f63
WHEEL_STATE_ADDRESS=0x16690C437740e5490bCe453bD5f91D44Ef819316
WHEEL_TICKS_POOL_ADDRESS=0xfC69C3F0Aa5C623F3470aa61Db8d799c2AE0CD38
WHEEL_ORACLE_ADDRESS=0x7c453BeF4328FD7dd1bA0aa0b4BAb3A3b86F8F60


export const wheel = {
  wheel: '0x81548eB86a497c105Af10273486933EA332e3e70' as `0x${string}`,
  authenticator: '0xD1ef62fB00009Ed5731102E9F749C04B87089e61' as `0x${string}`,
  config: '0xa86C7cAF87f8Ca42fAa593AC08fB10Df2B76Bb91' as `0x${string}`,
  swapper: '0xE52b4432F133A446f2eA6F7451052Afe9E2833B6' as `0x${string}`,
  tickHistory: '0x7207297BB7A1b9432299Ed4158fC6B527201d6d7' as `0x${string}`,
  state: '0x7A8513D6a6c66C42935741eA42BB7eD14be51bC5' as `0x${string}`,
  ticksPool: '0x5E3eF1633E650287B2F538325228e55389069606' as `0x${string}`,
  oracle: '0xc3216376Cb2f085480dA3FBd0D971e5A6dB44B5C' as `0x${string}`,
};


export const uniswap = {
  positionManager: '0x03a520b32C04BF3bEEf7BEb72E919cf822Ed34f1' as `0x${string}`,
  poolFactory: '0x33128a8fC17869897dcE68Ed026d694621f6FDfD' as `0x${string}`,
  v3Pool: '0xd0b53D9277642d899DF5C87A3966A349A798F224' as `0x${string}`,
}

export const tokens = {
  usdc: '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913' as `0x${string}`,
  weth: '0x4200000000000000000000000000000000000006' as `0x${string}`,
  token1: '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913' as `0x${string}`,
  token0: '0x4200000000000000000000000000000000000006' as `0x${string}`,
  //aToken0: '0xD4a0e0b9149BCee3C920d2E00b5dE09138fd8bb7' as `0x${string}`,
  // aToken1: '0x4e65fE4DbA92790696d040ac24Aa414708F5c0AB' as `0x${string}`,

}


*/

export const wheel = {
  wheel: '0x232205f47A202A4F1cCCF7E6Efd79f0af362b161' as `0x${string}`,
  authenticator: '0xe956B98ce733151e5761165f6F5CEbD4f7a66021' as `0x${string}`,
  config: '0x0b01Bd555809FD2b59DbF4eA3a6E585cfddDd6c5' as `0x${string}`,
  swapper: '0xdBB1725C40E00E9B0fC59637bDec237F1796C286' as `0x${string}`,
  tickHistory: '0x0294a3e29F204f60e0F2382709f28b32B5868331' as `0x${string}`,
  state: '0xAAabf1b8BC4459b6481d23Dcf7b84aBb94Df2019' as `0x${string}`,
  ticksPool: '0xcA1fd8F24aE9782097558A965B88937fd13a5464' as `0x${string}`,
  oracle: '0xf3adD05c0f6b6043646e28d8EA306f8395d03617' as `0x${string}`,
  eat: '0xe006590a98E51807935231Ef27e54F92e13CB1CA' as `0x${string}`,
  distribuitor: '0xA2e929825545EDB88fa2743A417736d45A0CB420' as `0x${string}`,
  priceToTick: '0xb6ef3f04d2eD3Af271825EAb41b00B90250aC23F' as `0x${string}`,
};

export const aave = {
  pool: '',
  // addressProvider: '0xe20fCBdBfFC4Dd138cE8b2E6FBb6CB49777ad64D' as `0x${string}`,
  addressProvider: '0x0E02EB705be325407707662C6f6d3466E939f3a0' as `0x${string}`,
}

export const uniswap = {
  positionManager: '0x03a520b32C04BF3bEEf7BEb72E919cf822Ed34f1' as `0x${string}`,
  poolFactory: '0x33128a8fC17869897dcE68Ed026d694621f6FDfD' as `0x${string}`,
  v3Pool: '0xd0b53D9277642d899DF5C87A3966A349A798F224' as `0x${string}`,
  seamV3Pool: '0xeDC7f0DFD9751ef95BB8786a3B130f490743bb0E' as `0x${string}`,
}

export const tokens = {
  usdc: '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913' as `0x${string}`,
  weth: '0x4200000000000000000000000000000000000006' as `0x${string}`,
  token1: '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913' as `0x${string}`,
  token0: '0x4200000000000000000000000000000000000006' as `0x${string}`,
  // aToken0: '0xD4a0e0b9149BCee3C920d2E00b5dE09138fd8bb7' as `0x${string}`,
  // aToken1: '0x4e65fE4DbA92790696d040ac24Aa414708F5c0AB' as `0x${string}`,

}

export const dev = '0x5A1EFb8C2C436669b30397695874bc8ca43ff897' as `0x${string}`;

export const wheelOperators = {
  //spinner: '0xcC34b9D74B36babCf156CbE1A4D31832623142cb' as `0x${string}`,
  // spinner: '0xc57052144ED0674E7641d1e42eEc7da7C1d25Ca4' as `0x${string}`,
  // spinner: '0x2501bE3AB50C335e70FDdf28DbCbcE89c87CbF16' as `0x${string}`,
  // spinner: '0xDf212B04e4cbae6dF74d5508EfaF443807a06D41' as `0x${string}`,
  spinner: '0x61e7AeBEb78FF62B683604fE08cf2A310f023188' as `0x${string}`,
}


export const tantalus = '0xDa78949758f6bC2DDD7D31c5a38489096Ec7d527' as `0x${string}`;


export const seam = {
  rewardsController: '0x91Ac2FfF8CBeF5859eAA6DdA661feBd533cD3780' as `0x${string}`,
  token: '0x1C7a460413dD4e964f96D8dFC56E7223cE88CD85' as `0x${string}`, // SEAM token
  escrowToken: '0x998e44232BEF4F8B033e5A5175BDC97F2B10d5e5' as `0x${string}`, // esSEAM token
}
