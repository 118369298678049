export default [{
  "type": "constructor",
  "inputs": [{
    "name": "_authenticator",
    "type": "address",
    "internalType": "address"
  }],
  "stateMutability": "nonpayable"
},
{
  "type": "function",
  "name": "authenticator",
  "inputs": [],
  "outputs": [{
    "name": "",
    "type": "address",
    "internalType": "contract IWheelAuthenticator"
  }],
  "stateMutability": "view"
},
{
  "type": "function",
  "name": "cashIn",
  "inputs": [{
    "name": "amount0",
    "type": "uint256",
    "internalType": "uint256"
  },
  {
    "name": "amount1",
    "type": "uint256",
    "internalType": "uint256"
  }],
  "outputs": [],
  "stateMutability": "payable"
},
{
  "type": "function",
  "name": "cashOut",
  "inputs": [{
    "name": "destination",
    "type": "address",
    "internalType": "address"
  },
  {
    "name": "amount0",
    "type": "uint256",
    "internalType": "uint256"
  },
  {
    "name": "amount1",
    "type": "uint256",
    "internalType": "uint256"
  },
  {
    "name": "real",
    "type": "bool",
    "internalType": "bool"
  }],
  "outputs": [],
  "stateMutability": "nonpayable"
},
{
  "type": "function",
  "name": "execute",
  "inputs": [{
    "name": "actions",
    "type": "tuple[]",
    "internalType": "struct ITheWheel.Action[]",
    "components": [{
      "name": "actionType",
      "type": "uint8",
      "internalType": "enum ITheWheel.ActionType"
    },
    {
      "name": "tick",
      "type": "int24",
      "internalType": "int24"
    },
    {
      "name": "asPecentage",
      "type": "bool",
      "internalType": "bool"
    },
    {
      "name": "amount0",
      "type": "uint256",
      "internalType": "uint256"
    },
    {
      "name": "amount1",
      "type": "uint256",
      "internalType": "uint256"
    },
    {
      "name": "liquidity",
      "type": "uint256",
      "internalType": "uint256"
    }]
  },
  {
    "name": "preBorrow0",
    "type": "uint256",
    "internalType": "uint256"
  },
  {
    "name": "preBorrow1",
    "type": "uint256",
    "internalType": "uint256"
  }],
  "outputs": [],
  "stateMutability": "nonpayable"
},
{
  "type": "function",
  "name": "initTicks",
  "inputs": [{
    "name": "ticks",
    "type": "int24[]",
    "internalType": "int24[]"
  }],
  "outputs": [],
  "stateMutability": "nonpayable"
},
{
  "type": "function",
  "name": "initialize",
  "inputs": [],
  "outputs": [],
  "stateMutability": "nonpayable"
},
{
  "type": "function",
  "name": "markTime",
  "inputs": [],
  "outputs": [],
  "stateMutability": "nonpayable"
},
{
  "type": "function",
  "name": "quit",
  "inputs": [],
  "outputs": [],
  "stateMutability": "nonpayable"
},
{
  "type": "function",
  "name": "rebalance",
  "inputs": [{
    "name": "amount0Out",
    "type": "uint256",
    "internalType": "uint256"
  },
  {
    "name": "amount1Out",
    "type": "uint256",
    "internalType": "uint256"
  }],
  "outputs": [],
  "stateMutability": "nonpayable"
},
{
  "type": "error",
  "name": "Unauthorized",
  "inputs": []
}];