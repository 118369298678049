import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export function LinearProgressWithLabel(props: LinearProgressProps & { value: number, labelColor?: string }) {
  const labelColor = props.labelColor || 'text.secondary';
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          sx={{ color: labelColor }}
        >{`${props.value.toFixed(2)}%`}</Typography>
      </Box>
    </Box>
  );
}
