
import React from 'react';
import { Typography, Box, Paper, Card, CardContent, Link, Popper } from '@mui/material';
import { red, green, blue, orange, grey } from '@mui/material/colors';
import { formatUnits, parseUnits } from 'viem';
import { ethDisplayValue, usdDisplayValue } from '../../transform/to-number-display';
import { EatStats } from '../../tantalus/wheel/actions/extreme-altruism';
import { toAddressDisplay, toAddressExplorerLink } from '../../transform/to-string-display';
import { WheelBalance } from '../../tantalus/wheel/actions';
import { DashboardViewState } from '../types';

interface ElementProps {
  state: DashboardViewState;
}

const Element: React.FC<ElementProps> = ({
  state
}) => {

  const { cash, rebalance, activations, debt, collateral, balance } = state;

  const amount0In = activations.amount0In + rebalance.amount0In - state.totalUsageCost / 2n ;
  const amount1In = activations.amount1In + rebalance.amount1In;
  const amount0Out = activations.amount0Out + rebalance.amount0Out + state.totalUsageCost / 2n;
  const amount1Out = activations.amount1Out + rebalance.amount1Out;

  const amount0 = amount0In - amount0Out;
  const amount1 = amount1In - amount1Out;

  activations.amount0In = activations.amount0In - state.totalUsageCost / 2n;
  activations.amount0Out = activations.amount0Out + state.totalUsageCost / 2n;

  const activationsAmount0 = activations.amount0In - activations.amount0Out;
  const activationsAmount1 = activations.amount1In - activations.amount1Out;

  rebalance.amount0In = rebalance.amount0In - state.totalUsageCost / 2n;
  rebalance.amount0Out = rebalance.amount0Out + state.totalUsageCost / 2n;

  const rebalanceAmount0 = rebalance.amount0In - rebalance.amount0Out;
  const rebalanceAmount1 = rebalance.amount1In - rebalance.amount1Out;

  
  const activationSellBepPrice: bigint = -1n * (activationsAmount0 != 0n && activationsAmount1 < 0n ? activationsAmount1 * parseUnits("1", 18) / activationsAmount0 : 0n);
  const activationBuyBepPrice: bigint = activationsAmount0 != 0n && activationsAmount1 > 0n ? activationsAmount1 * parseUnits("1", 18) / activationsAmount0 : 0n;

  const rebalanceSellBepPrice: bigint = -1n * (rebalanceAmount0 != 0n && rebalanceAmount1 < 0n ? rebalanceAmount1 * parseUnits("1", 18) / rebalanceAmount0 : 0n);
  const rebalanceBuyBepPrice: bigint = rebalanceAmount0 != 0n && rebalanceAmount1 > 0n ? rebalanceAmount1 * parseUnits("1", 18) / rebalanceAmount0 : 0n;

  return (
    <Card elevation={3} sx={{ padding: 3, margin: 2,  }}>
      <CardContent>
       
      <Typography variant="h6" textAlign={"justify"}>Activation</Typography>
        <Typography variant="h5" textAlign={"justify"}>SELL {activationsAmount0 > 0n ? ethDisplayValue(activationsAmount0) : ''}</Typography>
        <Typography variant="h5" style={{ color: red[100] }} textAlign={"justify"}>${usdDisplayValue(activationSellBepPrice)}</Typography>
        <Typography variant="h5" textAlign={"justify"}>BUY {activationBuyBepPrice > 0n ? usdDisplayValue(activationsAmount1) : ''}</Typography>
        <Typography variant="h5" style={{ color: green[100] }} textAlign={"justify"}>${usdDisplayValue(activationBuyBepPrice)}</Typography>
        <br />
        <Typography variant="h6" textAlign={"justify"}>Rebalance</Typography>
        <Typography variant="h5" textAlign={"justify"}>SELL {rebalanceAmount0 > 0n ? ethDisplayValue(rebalanceAmount0): ''}</Typography>
        <Typography variant="h5" style={{ color: red[100] }} textAlign={"justify"}>${usdDisplayValue(rebalanceSellBepPrice)}</Typography>
        <Typography variant="h5" textAlign={"justify"}>BUY { rebalanceAmount1 > 0n ? usdDisplayValue(rebalanceAmount1): ''}</Typography>
        <Typography variant="h5" style={{ color: green[100] }} textAlign={"justify"}>${usdDisplayValue(rebalanceBuyBepPrice)}</Typography>

      </CardContent>
    </Card>
  );
};

export default Element;