import { formatUnits, parseUnits } from "viem";
import bonnection from "../common/bonnection";
import { publicClient } from "../common/bonnection/base";
import wheel from "../abis/wheel";
import { fetchWheelBalance } from "../tantalus/wheel/actions";
let LAST_MIDDLE_REFERENCE: bigint = 0n;

export const MAX_PERCENTAGE = 100_0000n;

export async function init() {
  // get current tick
  const crt = await currentTick();
  const frame = ticksFrame(crt, 5n, 5n);
  // take the middle active tick

  const middleIdx = frame.length / 2;
  // first active tick left or right
  for (let i = 0; i < middleIdx; i++) {
    try {
      const state = await tickState(frame[middleIdx - i]);
      if (state.active0 > 0n || state.active1 > 0n) {
        LAST_MIDDLE_REFERENCE = frame[middleIdx - i];
        break;
      }
    } catch (e) {

    }
    try {
      const state2 = await tickState(frame[middleIdx + i]);
      if (state2.active0 > 0n || state2.active1 > 0n) {
        LAST_MIDDLE_REFERENCE = frame[middleIdx + i];
        break;
      }
    } catch (e) {

    }
  }

}



export async function currentTick(): Promise<bigint> {
  const result: bigint[] = await bonnection.base.ticksPool().read.currentTick() as bigint[];
  return result[0] as bigint;
}

export type TickState = {
  amount0Out: bigint;
  amount1Out: bigint;
  amount0In: bigint;
  amount1In: bigint;
  priceOut: bigint;
  priceIn: bigint;
  tokenId: bigint;
  active0: bigint;
  active1: bigint;
  liquidity: bigint;
  tick: bigint;
  spacesToMiddle: bigint;
  rawTicksToMiddle: bigint;
  feeGrowthInside0LastX128: bigint;
  feeGrowthInside1LastX128: bigint;
  status: string;
}

export async function tickState(tick: bigint) {
  const result: TickState = await bonnection.base.ticksPool().read.tickState([tick]) as TickState;

  result.tick = BigInt(result.tick);
  result.spacesToMiddle = BigInt(result.spacesToMiddle);
  result.rawTicksToMiddle = BigInt(result.rawTicksToMiddle);
  result.feeGrowthInside0LastX128 = BigInt(result.feeGrowthInside0LastX128);
  result.feeGrowthInside1LastX128 = BigInt(result.feeGrowthInside1LastX128);
  result.liquidity = BigInt(result.liquidity);
  result.tokenId = BigInt(result.tokenId);
  result.active0 = BigInt(result.active0);
  result.active1 = BigInt(result.active1);
  result.amount0In = BigInt(result.amount0In);
  result.amount1In = BigInt(result.amount1In);
  result.amount0Out = BigInt(result.amount0Out);
  result.amount1Out = BigInt(result.amount1Out);
  result.priceOut = BigInt(result.priceOut);
  result.priceIn = BigInt(result.priceIn);


  let status = "INACTIVE";
  if (result.active0 > 0n || result.active1 > 0n) {
    status = "DORMANT";
  }
  if (result.spacesToMiddle === 0n) {
    if (status === "DORMANT") {
      status = "ACTIVE";
    }
  }
  return {
    status: status,
    amount0Out: BigInt(result.amount0Out),
    amount1Out: BigInt(result.amount1Out),
    amount0In: BigInt(result.amount0In),
    amount1In: BigInt(result.amount1In),
    priceOut: BigInt(result.priceOut),
    priceIn: BigInt(result.priceIn),
    tokenId: BigInt(result.tokenId),
    active0: BigInt(result.active0),
    active1: BigInt(result.active1),
    liquidity: BigInt(result.liquidity),
    tick: BigInt(result.tick),
    spacesToMiddle: BigInt(result.spacesToMiddle),
    rawTicksToMiddle: BigInt(result.rawTicksToMiddle),
    feeGrowthInside0LastX128: BigInt(result.feeGrowthInside0LastX128),
    feeGrowthInside1LastX128: BigInt(result.feeGrowthInside1LastX128),
  } as TickState;
}

export async function hasTick(tick: bigint) {
  return await bonnection.base.ticksPool().read.hasTick([tick]);
}


export function toLowerTickBound(tick: bigint): bigint {
  const spacing: bigint = BigInt(10n);
  const mod: bigint = BigInt(tick) % spacing;
  tick = BigInt(tick);
  if (tick < 0n) {
    if (mod === 0n) {
      return tick - mod;
    }
    return tick - mod - spacing;
  } else {
    return tick - mod;
  }
}

export async function lotSize0() {
  const wheelBalance = await fetchWheelBalance();
  const available0 = (wheelBalance.b0 + wheelBalance.a0 - wheelBalance.d0);
  const l0 = await bonnection.base.wheelConfig().read.standardLotSize([
    available0
  ]);
  return BigInt(l0 as bigint);
}

export async function lotSize1() {
  const wheelBalance = await fetchWheelBalance();
  const available1 = wheelBalance.b1 + wheelBalance.a1 - wheelBalance.d1;
  const l1 = await bonnection.base.wheelConfig().read.standardLotSize([
    available1
  ]);
  return BigInt(l1 as bigint);
}

export async function getTick(tick: bigint): Promise<TickState> {
  return await tickState(tick);
}

export async function getCurrentTick(): Promise<bigint> {
  return await currentTick();
}

export async function getCurrentSpacedTick(): Promise<bigint> {
  const crt = await currentTick();
  return toLowerTickBound(crt);
}


export function ticksFrame(middle: bigint, radiusLeft: bigint, radiusRight?: bigint): bigint[] {
  const spacing = 10n;
  middle = toLowerTickBound(middle);
  const ticks: bigint[] = [];
  radiusRight = radiusRight || radiusLeft;
  for (let i = middle - radiusLeft * spacing; i <= middle + radiusRight * spacing; i += spacing) {
    ticks.push(i);
  }
  return ticks;
}

export enum WheelActionType {
  NONE = 0,
  DEACTIVATE = 1,
  ACTIVATE = 2,
  COLLECT = 3,
}
export type WheelAction = {
  actionType: WheelActionType;
  tick: bigint;
  asPercentage: boolean;
  amount0: bigint;
  amount1: bigint;
  liquidity: bigint;
}


export type WheelInOutValues = {
  amount0In: bigint;
  amount1In: bigint;
  amount0Out: bigint;
  amount1Out: bigint;
  priceOut: bigint;
  priceIn: bigint;
}

export type WheelOraclePrices = {
  collateral0: bigint;
  collateral1: bigint;
  market0: bigint;
  market1: bigint;
}

export type Profit = {
  profit0: bigint;
  profit1: bigint;
  allProfit0: bigint;
  allProfit1: bigint;
  health: bigint;
  operationCost: bigint;
  active0: bigint;
  active1: bigint;
  value0: bigint;
  value1: bigint;
};


export async function getHealth(): Promise<bigint> {
  const health = await bonnection.base.state().read.health();
  return BigInt(health as bigint);
}
export async function priceIn0(amount1: bigint): Promise<bigint> {
  const response = await bonnection.base.swapper().read.market1in0([amount1]);
  return BigInt(response as bigint);
}

export async function priceIn1(amount0: bigint): Promise<bigint> {
  const response = await bonnection.base.swapper().read.market0in1([amount0]);
  return BigInt(response as bigint);
}



export async function calculateProfit(): Promise<Profit> {

  const totalOperationCost = await bonnection.base.state().read.totalOperationCost() as bigint;

  const response = await bonnection.base.state().read.getCash() as WheelInOutValues;
  const cash: WheelInOutValues = {
    amount0In: BigInt(response.amount0In),
    amount1In: BigInt(response.amount1In),
    amount0Out: BigInt(response.amount0Out),
    amount1Out: BigInt(response.amount1Out),
    priceOut: BigInt(response.priceOut),
    priceIn: BigInt(response.priceIn),
  }


  const cashedInValue0 = cash.amount0In - cash.amount0Out;
  const cashedInValue1 = cash.amount1In - cash.amount1Out;

  let allProfit0 = 0n;
  let allProfit1 = 0n;

  // allProfit0 = profit0 + (profit1 > 0n ? await priceIn0(profit1) : await priceIn0(-profit1));
  // allProfit1 = profit1 + (profit0 > 0n ? await priceIn1(profit0) : await priceIn1(-profit0));


  return {
    profit0: 0n,
    profit1: 0n,
    allProfit0: allProfit0,
    allProfit1: allProfit1,
    health: await getHealth(),
    operationCost: totalOperationCost,
    active0: 0n,
    active1: 0n,
    value0: 0n,
    value1: 0n,
  }
}


/**
 * 
 * 
struct ObservationEntry {
    uint32 blockTimestamp;
    int24 currentTick;
    uint256 balance0;
    uint256 balance1;
    uint256 aBalance0;
    uint256 aBalance1;
    uint256 debt0;
    uint256 debt1;
    uint256 active0;
    uint256 active1;
}
 */

export type ObservationEntry = {
  blockTimestamp: number;
  time: Date;
  currentTick: number;
  balance0: bigint;
  balance1: bigint;
  aBalance0: bigint;
  aBalance1: bigint;
  debt0: bigint;
  debt1: bigint;
  active0: bigint;
  active1: bigint;
  totalValueIn0: bigint;
  totalValueIn1: bigint;
  price: number;
}


export type RawObservationEntry = {
  blockTimestamp: number;
  currentTick: number;
  balance0: bigint;
  balance1: bigint;
  aBalance0: bigint;
  aBalance1: bigint;
  debt0: bigint;
  debt1: bigint;
  active0: bigint;
  active1: bigint;
}

export async function getObservationsCount(): Promise<number> {
  const count = await bonnection.base.oracle().read.observationsCount();
  return Number(count);
}


export function priceFromTick(tick: number): number {
  // =(POW(1.0001, A2) / (pow(10, 6-18)))
  return Math.pow(1.0001, tick) / Math.pow(10, 6 - 18);
}

export function priceFromTickB(tick: number): bigint {
  // =(POW(1.0001, A2) / (pow(10, 6-18)))
  return parseUnits(`${Math.pow(1.0001, tick) / Math.pow(10, 6 - 18)}`, 6);
}

export function calculateValueOf0In1AtTick(tick: number, amount0: bigint): bigint {
  const price = priceFromTick(tick);
  let isPositive = true;
  if (amount0 < 0n) {
    isPositive = false;
    amount0 = -amount0;
  }
  let amount1 = Number(formatUnits(amount0, 18)) * price;
  if (!isPositive) {
    amount1 = -amount1;
  }
  return parseUnits(amount1.toString(), 6);
}

export function calculateValueOf1In0AtTick(tick: number, amount1: bigint): bigint {
  const price = priceFromTick(tick);
  let isPositive = true;
  if (amount1 < 0n) {
    isPositive = false;
    amount1 = -amount1;
  }
  let amount0 = Number(formatUnits(amount1, 6)) / price;
  if (!isPositive) {
    amount0 = -amount0;
  }
  return parseUnits(amount0.toString(), 18);
}

export async function getObservationAtIndex(index: number): Promise<ObservationEntry> {
  const observationRaw: RawObservationEntry = await bonnection.base.oracle().read.getObservation([index]) as RawObservationEntry;
  const value0 = BigInt(observationRaw.aBalance0) + BigInt(observationRaw.balance0) - BigInt(observationRaw.debt0) + BigInt(observationRaw.active0);
  const value1 = BigInt(observationRaw.aBalance1) + BigInt(observationRaw.balance1) - BigInt(observationRaw.debt1) + BigInt(observationRaw.active1);
  const observation: ObservationEntry = {
    blockTimestamp: Number(observationRaw.blockTimestamp),
    time: new Date(Number(observationRaw.blockTimestamp) * 1000),
    currentTick: Number(observationRaw.currentTick),
    balance0: BigInt(observationRaw.balance0),
    balance1: BigInt(observationRaw.balance1),
    aBalance0: BigInt(observationRaw.aBalance0),
    aBalance1: BigInt(observationRaw.aBalance1),
    debt0: BigInt(observationRaw.debt0),
    debt1: BigInt(observationRaw.debt1),
    active0: BigInt(observationRaw.active0),
    active1: BigInt(observationRaw.active1),
    totalValueIn0: BigInt(value0 + calculateValueOf1In0AtTick(observationRaw.currentTick, value1)),
    totalValueIn1: BigInt(value1 + calculateValueOf0In1AtTick(observationRaw.currentTick, value0)),
    price: priceFromTick(observationRaw.currentTick),
  };
  return observation;
}

export async function getObservations(fromIndex: number, toIndex: number): Promise<ObservationEntry[]> {
  // fetch observations from oracle
  const observationCount = await getObservationsCount();
  if (toIndex > observationCount) {
    toIndex = observationCount - 1;
  }
  if (fromIndex >= toIndex) {
    fromIndex = toIndex - 1;
  }
  if (fromIndex < 0) {
    fromIndex = 0;
  }
  if (fromIndex >= toIndex) {
    return [] as ObservationEntry[];
  }
  const observations: ObservationEntry[] = await Promise.all(
    Array.from({ length: toIndex - fromIndex }, (_, i) => getObservationAtIndex(fromIndex + i))
  );

  return observations;
}

export function filterByMean(observations: ObservationEntry[]): ObservationEntry[] {
  const mean = observations.reduce((sum, o) => sum + Number(formatUnits(o.totalValueIn1, 6)), 0) / observations.length;
  const stdDev = Math.sqrt(observations.reduce((sum, o) => sum + Math.pow(Number(formatUnits(o.totalValueIn1, 6)) - mean, 2), 0) / observations.length);
  return observations.filter(o => Math.abs(Number(formatUnits(o.totalValueIn1, 6)) - mean) < stdDev / 4);
}
