import { state } from "../../../common/bonnection/base"
import { WheelInOutValues } from "../types"

export const getRebalanceState = async (): Promise<WheelInOutValues> => {
  const result = await state().read.getRebalance() as WheelInOutValues;
  return {
    amount0In: BigInt(result.amount0In),
    amount1In: BigInt(result.amount1In),
    amount0Out: BigInt(result.amount0Out),
    amount1Out: BigInt(result.amount1Out),
    priceIn: BigInt(result.priceIn),
    priceOut: BigInt(result.priceOut),
  } as WheelInOutValues;
}

export const getDebtState = async (): Promise<WheelInOutValues> => {
  const result = await state().read.getDebt() as WheelInOutValues;
  return {
    amount0In: BigInt(result.amount0In),
    amount1In: BigInt(result.amount1In),
    amount0Out: BigInt(result.amount0Out),
    amount1Out: BigInt(result.amount1Out),
    priceIn: BigInt(result.priceIn),
    priceOut: BigInt(result.priceOut),
  } as WheelInOutValues;
}

export const getCashState = async (): Promise<WheelInOutValues> => {
  const result = await state().read.getCash() as WheelInOutValues;
  return {
    amount0In: BigInt(result.amount0In),
    amount1In: BigInt(result.amount1In),
    amount0Out: BigInt(result.amount0Out),
    amount1Out: BigInt(result.amount1Out),
    priceIn: BigInt(result.priceIn),
    priceOut: BigInt(result.priceOut),
  } as WheelInOutValues;
}

export const getCollateralState = async (): Promise<WheelInOutValues> => {
  const result = await state().read.getCollateral() as WheelInOutValues;
  return {
    amount0In: BigInt(result.amount0In),
    amount1In: BigInt(result.amount1In),
    amount0Out: BigInt(result.amount0Out),
    amount1Out: BigInt(result.amount1Out),
    priceIn: BigInt(result.priceIn),
    priceOut: BigInt(result.priceOut),
  } as WheelInOutValues;
}

export const getHealth = async () => {
  return BigInt(await state().read.health() as bigint);
}

export const getTotalOperationCost = async () => {
  return BigInt(await state().read.totalOperationCost() as bigint);
}

