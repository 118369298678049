import { Abi } from "viem";
import abis from "../../../abis"
import addresses from "../../../addresses"
import { createListener } from "../../../common/bonnection/base";

export enum MarketSwapSide {
  BUY = "BUY",
  SELL = "SELL",
}

export type MarketSwapEventArgs = {
  sender: `0x${string}`;
  recipient: `0x${string}`;
  amount0: bigint;
  amount1: bigint;
  sqrtPriceX96: bigint;
  liquidity: bigint;
  tick: bigint;
  side: MarketSwapSide;
}

export const onMarketSwap = createListener<MarketSwapEventArgs>({
  address: addresses.base.uniswap.v3Pool,
  event: "Swap",
  abi: abis.uniswap.v3pool as Abi,
  followDefault: true,
}, (args: any) => {
  return {
    sender: args.sender as `0x${string}`,
    recipient: args.recipient as `0x${string}`,
    amount0: BigInt(args.amount0 ?? 0),
    amount1: BigInt(args.amount1 ?? 0),
    sqrtPriceX96: BigInt(args.sqrtPriceX96 ?? 0),
    liquidity: BigInt(args.liquidity ?? 0),
    tick: BigInt(args.tick ?? 0),
    side: args[2] > 0n ? MarketSwapSide.SELL : MarketSwapSide.BUY,
  }
});
