import { authenticator, tantalusBalance } from "../../../common/bonnection/base";
import { calculateValueOf1In0AtTick } from "../../../common/tantalus";
import { currentTick } from "./current-tick";
import { calculateValueOf0In1AtTick } from "./math";
import { tickState } from "./tick-state";
import { ticksFrame } from "./ticks-frame";

export type WheelBalance = {
  b0: bigint;
  b1: bigint;
  a0: bigint;
  a1: bigint;
  d0: bigint;
  d1: bigint;
  tantalus: {
    weth: bigint;
    eth: bigint;
    usdc: bigint;
    seam: bigint;
    esSeam: bigint;
  },
  active: {
    token0: bigint;
    token1: bigint;
  }
}

export const CACHED_WHEEL_BALANCE: WheelBalance = {
  b0: 0n,
  b1: 0n,
  a0: 0n,
  a1: 0n,
  d0: 0n,
  d1: 0n,
  tantalus: {
    weth: 0n,
    eth: 0n,
    usdc: 0n,
    seam: 0n,
    esSeam: 0n,
  },
  active: {
    token0: 0n,
    token1: 0n,
  }
}

export let CACHED_WHEEL_BALANCE_TIMESTAMP: number = 0;
export let MIN_CACHE_INTERVAL: number = 1000;

export const getTotalActive = async () => {
  const ct = await currentTick();
  const frame = await ticksFrame(ct, 5n, 5n);
  const states = await Promise.all(frame.map(async (t) => {
    return await tickState(t);
  }));
  const active = states.reduce((acc, s) => {
    return {
      token0: acc.token0 + s.active0,
      token1: acc.token1 + s.active1,
    }
  }, { token0: 0n, token1: 0n });
  return active;
}

export const wheelBalanceIn1 = async (balance?: WheelBalance): Promise<WheelBalance> => {
  const ct = Number(await currentTick());
  balance = balance || await fetchWheelBalance();
  return {
    b0: calculateValueOf0In1AtTick(ct, balance.b0),
    b1: balance.b1,
    a0: calculateValueOf0In1AtTick(ct, balance.a0),
    a1: balance.a1,
    d0: calculateValueOf0In1AtTick(ct, balance.d0),
    d1: balance.d1,
    tantalus: {
      weth: calculateValueOf0In1AtTick(ct, balance.tantalus.weth),
      eth: calculateValueOf0In1AtTick(ct, balance.tantalus.eth),
      usdc: balance.tantalus.usdc,
      seam: calculateValueOf0In1AtTick(ct, balance.tantalus.seam),
      esSeam: calculateValueOf0In1AtTick(ct, balance.tantalus.esSeam),
    },
    active: {
      token0: calculateValueOf0In1AtTick(ct, balance.active.token0),
      token1: balance.active.token1,
    }
  }
}

export const wheelBalanceIn0 = async (balance?: WheelBalance): Promise<WheelBalance> => {
  const ct = Number(await currentTick());
  balance = balance || await fetchWheelBalance();
  return {
    b0: balance.b0,
    b1: calculateValueOf1In0AtTick(ct, balance.b1),
    a0: balance.a0,
    a1: calculateValueOf1In0AtTick(ct, balance.a1),
    d0: balance.d0,
    d1: calculateValueOf1In0AtTick(ct, balance.d1),
    tantalus: {
      weth: balance.tantalus.weth,
      eth: balance.tantalus.eth,
      usdc: calculateValueOf1In0AtTick(ct, balance.tantalus.usdc),
      seam: balance.tantalus.seam,
      esSeam: balance.tantalus.esSeam,
    },
    active: {
      token0: balance.active.token0,
      token1: calculateValueOf1In0AtTick(ct, balance.active.token1),
    }
  }
}

export const fetchWheelBalance = async (force: boolean = false) => {
  if (!force && (Date.now() - CACHED_WHEEL_BALANCE_TIMESTAMP) < MIN_CACHE_INTERVAL) {
    return CACHED_WHEEL_BALANCE;
  }

  const balances: bigint[] = await authenticator().read.balances() as bigint[];
  const aBalances: bigint[] = await authenticator().read.aBalances() as bigint[];
  const debts: bigint[] = await authenticator().read.debts() as bigint[];
  const tantalus = await tantalusBalance();
  const active = await getTotalActive();

  const b: WheelBalance = {
    b0: BigInt(balances[0]),
    b1: BigInt(balances[1]),
    a0: BigInt(aBalances[0]),
    a1: BigInt(aBalances[1]),
    d0: BigInt(debts[0]),
    d1: BigInt(debts[1]),
    tantalus: tantalus,
    active: active,
  }

  CACHED_WHEEL_BALANCE.b0 = b.b0;
  CACHED_WHEEL_BALANCE.b1 = b.b1;
  CACHED_WHEEL_BALANCE.a0 = b.a0;
  CACHED_WHEEL_BALANCE.a1 = b.a1;
  CACHED_WHEEL_BALANCE.d0 = b.d0;
  CACHED_WHEEL_BALANCE.d1 = b.d1;
  CACHED_WHEEL_BALANCE.tantalus = b.tantalus;

  return b;
}

export const getWheelBalance = () => {
  return CACHED_WHEEL_BALANCE;
}


export const initWheelBalance = async () => {
  return await fetchWheelBalance(true);
}
