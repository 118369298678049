import { Box, Chip, Tooltip } from '@mui/material';
import * as React from 'react';
import { formatUnits } from 'viem';
import { ObservationEntry, getObservationsCount, getObservations, filterByMean } from '../common/tantalus';
import { LineChart } from '@mui/x-charts';
export type ObservationsProps = {

}

function Observations({
  
}: ObservationsProps) {
  
  const [lastKnownObservationsCount, setLastKnownObservationsCount] = React.useState<number>(0);
  const [observations, setObservations] = React.useState<ObservationEntry[]>([]);

  const lastKnownObservationsTimer = React.useRef<NodeJS.Timeout | null>(null);
  React.useEffect(() => {

    const updateObservationsCount = async () => {
      try {
        const count = await getObservationsCount();
        let useLastKnown = lastKnownObservationsCount;
        if (lastKnownObservationsCount === 0) {
          setLastKnownObservationsCount(count - 10);
          useLastKnown = count - 10;
        }
        if (count > lastKnownObservationsCount) {
          const newObservations = await getObservations(useLastKnown-1, count);
          setLastKnownObservationsCount(count);
          setObservations(filterByMean([
            ...observations,
            ...newObservations,
          ]));
        }
      } catch (e) {
        console.error('updateObservationsCount', e);
      }
    };

    const scheduleObservationsCountUpdate = () => {
      if (lastKnownObservationsTimer.current) {
        clearTimeout(lastKnownObservationsTimer.current);
      }
      lastKnownObservationsTimer.current = setTimeout(() => {
        updateObservationsCount();
      }, 1000);
    };

    updateObservationsCount();

    return () => {
      if (lastKnownObservationsTimer.current) {
        clearTimeout(lastKnownObservationsTimer.current);
      }
    };
  });


  return (
    
    <React.Fragment>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100vw',
      }}>
      <LineChart
        xAxis={[{
          data: observations.map((o, _idx) => o.time),
          id: 'Time',
          valueFormatter: (v) => new Date(v).toUTCString(),
          scaleType: 'time',
        }]}
        series={[{
          id: 'ETH',
          data: observations.map((o) => Number(formatUnits(o.totalValueIn0, 18))),
          label: 'ETH',
          curve: "natural",
        }
        ]}
          margin={{ left: 70 }}
          
      ></LineChart>
      <LineChart
        xAxis={[{
          data: observations.map((o, _idx) => o.time),
          id: 'Time',
          valueFormatter: (v) => new Date(v).toUTCString(),
          scaleType: 'time',
        }]}
        series={[{
          id: 'USDC',
          data: observations.map((o) => Number(formatUnits(o.totalValueIn1, 6))),
          label: 'USDC',
          curve: "natural",
        }
        ]}
        margin={{ left: 70 }}
      ></LineChart>
      
      <LineChart
         xAxis={[{
          data: observations.map((o, _idx) => o.time),
          id: 'Time',
          valueFormatter: (v) => new Date(v).toUTCString(),
          scaleType: 'time',
        }]}
        series={[{
          id: 'Price',
          label: 'Price',
          data: observations.map((o) => Number(o.price)),
          
          curve: "natural",
        }
        ]}
        margin={{ left: 70 }}
        ></LineChart>
    </Box>
    </React.Fragment>
  );
}

export default Observations;
