import * as React from 'react';
import { Box, Chip, Grid, Typography } from '@mui/material';
import { addresses } from '../common/constants';
import ValueDisplay from '../components/ValueDisplay';
import bonnection from '../common/bonnection';
import { calculateProfit, getCurrentSpacedTick, priceFromTick, Profit, ticksFrame } from '../common/tantalus';
import { formatUnits, parseUnits } from 'viem';
import TickView from './single/TickView';
import { ticksWatcher } from '../tantalus/wheel/watch/ticks';
import { MarketSwapEventArgs, onMarketSwap } from '../tantalus/wheel/listeners/market';
import { currentTick, getRebalanceState, overallTicksHistory, WheelInOutValues } from '../tantalus/wheel/actions';
export type TicksViewProps = {
}

export type AssetsBalance = {
  ether: bigint;
  token0: bigint;
  token1: bigint;
  aToken0: bigint;
  aToken1: bigint;
  dToken0: bigint;
  dToken1: bigint;
};



function TicksView({ }: TicksViewProps) {
  const [currentTickT, setCurrentTick] = React.useState<bigint>(0n);
  const [overallTickHistory, setOverallTickHistory] = React.useState<WheelInOutValues>({
    amount0In: 0n,
    amount0Out: 0n,
    amount1In: 0n,
    amount1Out: 0n,
    priceIn: 0n,
    priceOut: 0n,
  });
  const [ticksInView, setTicksInView] = React.useState<bigint[]>([]);
  const currentTickTimer = React.useRef<NodeJS.Timeout | null>(null);
  const overallTickTimer = React.useRef<NodeJS.Timeout | null>(null);
  React.useEffect(() => {

    const scheduleCurrentUpdate = () => {
      if (currentTickTimer.current) {
        clearTimeout(currentTickTimer.current);
      }
      currentTickTimer.current = setTimeout(async () => {
        const ct = await currentTick();
        setCurrentTick(ct);
        scheduleCurrentUpdate();
      }, 1000);
    };

    const scheduleOverallUpdate = () => {
      if (overallTickTimer.current) {
        clearTimeout(overallTickTimer.current);
      }
      overallTickTimer.current = setTimeout(async () => {
        const oh = await overallTicksHistory();
        const reba = await getRebalanceState();
        oh.amount0In += reba.amount0In;
        oh.amount0Out += reba.amount0Out;
        oh.amount1In += reba.amount1In;
        oh.amount1Out += reba.amount1Out;
        setOverallTickHistory(oh);
        scheduleOverallUpdate();
      }, 500);
    };


    const marketHappend = async (event: MarketSwapEventArgs) => {
      
      setCurrentTick(BigInt(event.tick ?? 0n));
      const ticks = ticksFrame(event.tick, 8n);
      ticks.sort((a, b) => Number( b-a));
      setTicksInView(ticks);
      setCurrentTick(BigInt(event.tick ?? 0n));
      scheduleOverallUpdate();
    };

    const initiate = async () => {

      const ct = await currentTick();
      const oh = await overallTicksHistory();
      setOverallTickHistory(oh);
      setCurrentTick(ct);
      const ticks = ticksFrame(ct, 8n);
      ticks.sort((a, b) => Number(b - a));
      setTicksInView(ticks);
    }

    onMarketSwap.attach(marketHappend);    

    initiate();
    return () => {
      onMarketSwap.detach(marketHappend);      

    }
  }, []);

  

  if (ticksInView.length == 0 && currentTickT == 0n) {
    
    return (
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}>
        <Typography variant={'h6'}>Loading...</Typography>
      </Box>
    );
  }
  return (
    <React.Fragment>
      
      <Grid container>
        <Grid item xs={12}>
        {ticksInView.map((tickId) => {
          return (
            <TickView key={tickId} overallHistory={overallTickHistory} tickId={tickId} />
          );
        })}
          </Grid>
        </Grid>
      
    </React.Fragment>
  );
}

export default TicksView;
