
import React from 'react';
import { Typography, Box, Paper, Card, CardContent, Link, Popper } from '@mui/material';
import { red, green, blue, orange, grey } from '@mui/material/colors';
import { formatUnits, parseUnits } from 'viem';
import { ethDisplayValue, usdDisplayValue } from '../../transform/to-number-display';
import { EatStats } from '../../tantalus/wheel/actions/extreme-altruism';
import { toAddressDisplay, toAddressExplorerLink } from '../../transform/to-string-display';

interface ElementProps {
  eatStats: EatStats;
}

const Element: React.FC<ElementProps> = ({
  eatStats
}) => {

  return (
    <Card elevation={3} sx={{ padding: 3, margin: 2,  }}>
      <CardContent>
        <Typography variant="h4" textAlign={"justify"}>EAT</Typography>
        <Typography variant="h5" textAlign={"justify"}>Average ${usdDisplayValue(eatStats.average)}</Typography>
        <Typography variant="h6" textAlign={"justify"} ><Link href={toAddressExplorerLink(eatStats.next)} target="_blank">Next</Link> ${usdDisplayValue(eatStats.nextTargetAmount) }</Typography>
        <Typography variant="h6" textAlign={"justify"}>Total ${usdDisplayValue(eatStats.total)}</Typography>
        <Typography variant="h6" textAlign={"justify"}>Acc ${usdDisplayValue(eatStats.distribuitorBalance.usdc)}</Typography>
        <Typography variant="h6" textAlign={"justify"}>Ops {ethDisplayValue(eatStats.distribuitorBalance.weth, 8)}</Typography>
      </CardContent>
    </Card>
  );
};

export default Element;