
import React from 'react';
import { Typography, Box, Paper, Card, CardContent, LinearProgress } from '@mui/material';
import { red, green, blue, orange, grey } from '@mui/material/colors';
import { formatUnits, parseUnits } from 'viem';
import { ethDisplayValue, percentageDisplayValue, usdDisplayValue } from '../../transform/to-number-display';
import { WheelBalance } from '../../tantalus/wheel/actions';
import { DashboardViewState } from '../types';
import { calculateValueOf0In1AtTick } from '../../tantalus/wheel/actions/math';
import { LinearProgressWithLabel } from './ProgressWithLabel';


interface ElementProps {
  value: bigint;
  threshold: bigint;
  criticalThreshold?: bigint;
  balance: WheelBalance;
  balanceIn1: WheelBalance;
  balanceIn0: WheelBalance;
  state: DashboardViewState;
}

const Element: React.FC<ElementProps> = ({
  value, threshold, criticalThreshold = parseUnits("1.1", 18),
  balance,
  balanceIn1,
  state
}) => {

  const isRepayingDebtPriority = value < threshold;
  const isCritical = value < criticalThreshold;
  const color = isCritical ? red[500] : isRepayingDebtPriority ? orange[500] : green[500];
  const status = isCritical ? "Critical" : isRepayingDebtPriority ? "Working" : "Grinning";
  
  const health0 = 100_0000n - (balance.a0 > 0 ? (balance.d0 * 100_0000n) / balance.a0 : 0n);
  const health1 = 100_0000n - (balance.a1 > 0 ? (balance.d1 * 100_0000n) / balance.a1 : 0n);

  const wheelTotal0 = balanceIn1.a0 - balanceIn1.d0 + balanceIn1.active.token0;
  const wheelTotal1 = balanceIn1.a1 - balanceIn1.d1 + balanceIn1.active.token1;
  const wheelTotal = wheelTotal0 + wheelTotal1;
  const position0Ratio = (wheelTotal > 0 ? wheelTotal0 * 100_0000n / wheelTotal : 0n); 
  const position1Ratio = wheelTotal > 0 ? wheelTotal1 * 100_0000n / wheelTotal : 0n;
  
  const cash0 = state.cash.amount0In - state.cash.amount0Out;
  const cash1 = state.cash.amount1In - state.cash.amount1Out;
  const current0 = state.balance.a0 - state.balance.d0 + state.balance.active.token0;
  const current1 = state.balance.a1 - state.balance.d1 + state.balance.active.token1;
  const current0WithT = current0 + state.balance.tantalus.eth + state.balance.tantalus.weth;
  const current1WithT = current1 + state.balance.tantalus.usdc;
  const cash0WithT = cash0 + parseUnits("0.2", 18) + parseUnits("0.2", 18);
  const cash1WithT = cash1 + parseUnits("100", 6);
  let diff0 = current0 - cash0;
  let diff1 = current1 - cash1;
  let diff0WithT = current0WithT - cash0WithT;
  let diff1WithT = current1WithT - cash1WithT;
  const profit1 = calculateValueOf0In1AtTick(Number(state.currentTick), diff0) + diff1;
  const profit1WithT = calculateValueOf0In1AtTick(Number(state.currentTick), diff0WithT) + diff1WithT;
  return (
    <Card elevation={3} sx={{ padding: 3, margin: 2,  }}>
      <CardContent>
        <Typography variant="h4" textAlign={"center"} style={{ color: profit1 > 0n ? green[200] : profit1 == 0n ? grey[500] : red[500] }}>${usdDisplayValue(balanceIn1.a0 + balanceIn1.a1 - balanceIn1.d0 - balanceIn1.d1 + balanceIn1.tantalus.eth + balanceIn1.tantalus.weth + balanceIn1.tantalus.usdc + balanceIn1.active.token0 + balanceIn1.active.token1)}</Typography>
        <Typography variant="h6" textAlign={"center"}>wuP/L <br /> ${usdDisplayValue(profit1)} / ${usdDisplayValue(profit1WithT)}</Typography>
        
        <Typography variant="h6" textAlign={"center"}>
          Wheel Position <br />
          {position0Ratio > position1Ratio ? "ETH " + percentageDisplayValue(position0Ratio) : "USDC " + percentageDisplayValue(position1Ratio)}</Typography>
        <Typography variant="h6" textAlign={"center"} style={{ color }}>{status}</Typography>
        <Typography variant="body1" textAlign={"center"}>ETH Health 
          <br />
          <LinearProgressWithLabel variant={health0 <= 50_0000n ? "buffer" : "determinate"}
            color="primary" value={Number(health0) / 10000}
            valueBuffer={100 - Number(health0) / 10000}
            labelColor={health0 <= 50_0000n ? red[500] : green[500]}
          />
        </Typography>
        
        <Typography variant="body1" textAlign={"center"}>
USD Health
          <LinearProgressWithLabel aria-label={percentageDisplayValue(health1, 2)} color="secondary" variant={health1 <= 50_0000n ? "buffer" : "determinate"}
            value={Number(health1) / 10000}
            valueBuffer={100 - Number(health1) / 10000}
            labelColor={health1 <= 50_0000n ? red[500] : green[500]}
          />
        </Typography>
      </CardContent>
    </Card>
  );
};

export default Element;