import erc20Abi from "../abis/erc20";
import wheelAbi from "../abis/wheel/wheel"
import swapperAbi from "../abis/wheel/swapper";
import authenticatorAbi from "../abis/wheel/authenticator";
import configAbi from "../abis/wheel/config";
import oracleAbi from "../abis/wheel/oracle";
import ticksPoolAbi from "../abis/wheel/ticks-pool";
import stateAbi from "../abis/wheel/state";
import tickHistoryAbi from "../abis/wheel/tick-history";
import wheelEatAbi from "../abis/wheel/eat";
import wheelDistribuitorAbi from "../abis/wheel/distribuitor";

import positionManagerAbi from "../abis/uniswap/positions-manager";
import poolAbi from "../abis/uniswap/v3pool";
import { Abi } from "viem";


export const RPC = "wss://burned-dawn-water.base-mainnet.quiknode.pro/0b37b7626b6746614535e2b40727be0f427324c6/"// "wss://ws.base.nodes.dedi.ird.ro/"

export const USDC = "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913";
export const WETH = "0x4200000000000000000000000000000000000006";
export const TANTALUS = "0xDa78949758f6bC2DDD7D31c5a38489096Ec7d527";
export const POSITION_MANAGER = "0x03a520b32C04BF3bEEf7BEb72E919cf822Ed34f1";
export const POOL = "0xd0b53D9277642d899DF5C87A3966A349A798F224";
export const ATOKEN0 = "0xD4a0e0b9149BCee3C920d2E00b5dE09138fd8bb7";
export const ATOKEN1 = "0x4e65fE4DbA92790696d040ac24Aa414708F5c0AB";
export const DTOKEN0 = "0x24e6e0795b3c7c71D965fCc4f371803d1c1DcA1E";
export const DTOKEN1 = "0x59dca05b6c26dbd64b5381374aAaC5CD05644C28";
export const WHEEL = "0x81548eB86a497c105Af10273486933EA332e3e70";
export const SWAPPER = "0xE52b4432F133A446f2eA6F7451052Afe9E2833B6";
export const CONFIG = "0xa86C7cAF87f8Ca42fAa593AC08fB10Df2B76Bb91";
export const TICKS_POOL = "0x5E3eF1633E650287B2F538325228e55389069606";
export const ORACLE = "0xc3216376Cb2f085480dA3FBd0D971e5A6dB44B5C";
export const TICK_HISTORY = "0x7207297BB7A1b9432299Ed4158fC6B527201d6d7";
export const STATE = "0x7A8513D6a6c66C42935741eA42BB7eD14be51bC5";
export const AUTHENTICATOR = "0xD1ef62fB00009Ed5731102E9F749C04B87089e61";
export const EAT = "0xe006590a98E51807935231Ef27e54F92e13CB1CA";
export const DISTRIBUITOR = "0xA2e929825545EDB88fa2743A417736d45A0CB420";
export const addresses = {
  usdc: USDC as `0x${string}`,
  weth: WETH as `0x${string}`,

  positionManager: POSITION_MANAGER as `0x${string}`,
  pool: POOL as `0x${string}`,
  atoken0: ATOKEN0 as `0x${string}`,
  atoken1: ATOKEN1 as `0x${string}`,
  dtoken0: DTOKEN0 as `0x${string}`,
  dtoken1: DTOKEN1 as `0x${string}`,
  tantalus: TANTALUS as `0x${string}`,
  swapper: SWAPPER as `0x${string}`,
  wheel: WHEEL as `0x${string}`,
  config: CONFIG as `0x${string}`,
  ticksPool: TICKS_POOL as `0x${string}`,
  oracle: ORACLE as `0x${string}`,
  tickHistory: TICK_HISTORY as `0x${string}`,
  state: STATE as `0x${string}`,
  authenticator: AUTHENTICATOR as `0x${string}`,
  eat: EAT as `0x${string}`,
  distribuitor: DISTRIBUITOR as `0x${string}`,
}

export const abis = {
  erc20: erc20Abi as Abi,
  wheel: wheelAbi as Abi,
  swapper: swapperAbi as Abi,
  positionManager: positionManagerAbi as Abi,
  pool: poolAbi as Abi,
  authenticator: authenticatorAbi as Abi,
  config: configAbi as Abi,
  oracle: oracleAbi as Abi,
  ticksPool: ticksPoolAbi as Abi,
  state: stateAbi as Abi,
  tickHistory: tickHistoryAbi as Abi,
}
