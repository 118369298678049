import bonnection from "../../../common/bonnection";

export type WheelSetConfig = {
  lotSizePercentage: bigint;
  healthScoreThreshold: bigint;
  collateralUsageThreshold: bigint;
}

let CACHED_CONFIG: WheelSetConfig = {
  lotSizePercentage: 0n,
  healthScoreThreshold: 0n,
  collateralUsageThreshold: 0n
}



export function getConfig() {
  return CACHED_CONFIG;
}

export async function initWheelConfig(): Promise<WheelSetConfig> {
  const lotSize = BigInt(await bonnection.base.wheelConfig().read.lotSizePercentage() as bigint);
  const healthScore = BigInt(await bonnection.base.wheelConfig().read.healthScoreThreshold() as bigint);
  const collateralUsage = BigInt(await bonnection.base.wheelConfig().read.collateralUsageThreshold() as bigint);
  CACHED_CONFIG = {
    lotSizePercentage: lotSize,
    healthScoreThreshold: healthScore,
    collateralUsageThreshold: collateralUsage
  };

  return {
    lotSizePercentage: lotSize,
    healthScoreThreshold: healthScore,
    collateralUsageThreshold: collateralUsage
  }
}



export const getDev = async () => {
  return await bonnection.base.authenticator().read.dev() as `0x${string}`;
}

export const getAdmin = async () => {
  return await bonnection.base.authenticator().read.admin() as `0x${string}`;
}
