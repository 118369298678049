import * as React from 'react';
import { Box, Button, Chip, Grid, List, ListItem, Typography } from '@mui/material';
import { ValueEitherOr } from './single/ValueEitherOr';
import { CalculatedProfit, calculateProfit, isWheelBuying, isWheelNotRebaTrading, isWheelSelling } from '../tantalus/wheel/actions';
import { BarChart, PieChart } from '@mui/x-charts';
import { formatUnits, parseUnits } from 'viem';
import ValueDisplay from './ValueDisplay';
import { percentageOf } from '../tantalus/wheel/actions/math';
export type QuickViewProps = {
}

export type AssetsBalance = {
  ether: bigint;
  token0: bigint;
  token1: bigint;
  aToken0: bigint;
  aToken1: bigint;
  dToken0: bigint;
  dToken1: bigint;
};



function QuickView({ }: QuickViewProps) {
  const [profit, setProfit] = React.useState<CalculatedProfit | null>(null);
  const [viewBars, setViewBars] = React.useState<boolean>(false);
  const balanceRefreshTimer = React.useRef<NodeJS.Timeout | null>(null);
  const [showEthValue, setShowEthValue] = React.useState<boolean>(true);

  React.useEffect(() => {


    
    
    // schedule balance update
    const scheduleBalanceUpdate = () => {
      if (balanceRefreshTimer.current) {
        clearTimeout(balanceRefreshTimer.current);
      }
      balanceRefreshTimer.current = setTimeout(() => {
        updateBalances();
      }, 2000);
    };

    // update balances
    const updateBalances = async () => {
      try {
        
        const profit = await calculateProfit();
        console.log(profit)
        setProfit(profit);
      } catch (e) {
        console.error('updateBalances', e);
      }
      scheduleBalanceUpdate();
    };

    scheduleBalanceUpdate();
    return () => {
      // ethereum.provider.off('block', onBlockNumber);

    }
  }, []);

  const switchDisplayedValue = () => {
    setShowEthValue(!showEthValue);
  }

  if (!profit) {
    
    return (
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}>
        <Typography variant={'h6'}>Loading...</Typography>
      </Box>
    );
  }
  return (
    <React.Fragment>
      

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} >
            <List >
              <ValueEitherOr eth={profit?.health!} usdc={parseUnits(formatUnits(profit?.health!, 18), 8)} ethSymbol={'Health'} usdcSymbol={'HealthX'} asItem={true} />
            <ListItem >
              {(!isWheelNotRebaTrading(profit!) && isWheelBuying(profit!))
                ? <Chip label="Wheel Is Buying (EAT ON)" color="primary" />
                : (!isWheelNotRebaTrading(profit!) && isWheelSelling(profit!)
                  ? <Chip label="Wheel Is Selling (EAT ON)" color="secondary" />
                  : <Chip label="Wheel Is Neutral (EAT OFF)" color="info" />)}  
           </ListItem>
            <ValueEitherOr eth={profit?.in0.livePrice!} usdc={profit?.in1.livePrice!} ethSymbol={'ETH/USDC'} usdcSymbol={'USDC/ETH'} side='usdc' asItem={true} />
              <ValueEitherOr eth={profit?.totalValueIn0!} usdc={profit?.totalValueIn1!} asItem={true} prefix={'Value '} side='usdc' />
            
              <ValueEitherOr eth={profit?.in0.totalOperationCost} usdc={profit?.in1.totalOperationCost} side='usdc' asItem={true} prefix={'Op cost '} />
            </List>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
          <ValueEitherOr eth={profit?.in0.all0!} usdc={profit?.in1.all0!} asItem={true} prefix={'Out Now '} ethSymbol='ETH' usdcSymbol='ETH in USDC' />
            <ValueEitherOr eth={profit?.in0.all1!} usdc={profit?.in1.all1!} asItem={true} prefix={'Out Now '} ethSymbol='USDC in ETH' side='usdc' usdcSymbol='USDC' />
            
          {profit.in0.static0In > 0 ? <ValueEitherOr eth={profit?.in0.static0In!} usdc={profit?.in1.static0In!} asItem={true} prefix={'Static IN '} ethSymbol='ETH' usdcSymbol='ETH in USDC' /> : null}
            <ValueEitherOr eth={profit?.in0.static1In!} usdc={profit?.in1.static1In!} asItem={true} prefix={'Static IN '} ethSymbol='USDC in ETH' usdcSymbol='USDC'  side='usdc' />
            <ValueEitherOr eth={profit?.in0.fluidAmount0In!} usdc={profit?.in1.fluidAmount0In!} asItem={true} prefix={'Fluid IN '} ethSymbol='ETH' usdcSymbol='ETH in USDC' />
            <ValueEitherOr eth={profit?.in0.fluidAmount1In!} usdc={profit?.in1.fluidAmount1In!} asItem={true} prefix={'Fluid IN '} ethSymbol='USDC in ETH' side='usdc' usdcSymbol='USDC' />
            
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <List>
              
              <ValueEitherOr eth={profit?.in0.free0!} usdc={profit?.in1.free0!} asItem={true} prefix={'Free '} ethSymbol='ETH' usdcSymbol='ETH in USDC' />
              <ValueEitherOr eth={profit?.in0.free1!} usdc={profit?.in1.free1!} asItem={true} prefix={'Free '} ethSymbol='USDC in ETH' usdcSymbol='USDC' side='usdc' />
              <ValueEitherOr eth={profit?.in0.active0!} usdc={profit?.in1.active0!} asItem={true} prefix={'Active '} ethSymbol='ETH' usdcSymbol='ETH in USDC' />
              <ValueEitherOr eth={profit?.in0.active1!} usdc={profit?.in1.active1!} asItem={true} prefix={'Active '} ethSymbol='USDC in ETH' usdcSymbol='USDC' side='usdc' />
              
              
              
            </List>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
            <List>
              
            <ValueEitherOr eth={profit?.in0.rebalance.amount0In-profit?.in0.rebalance.amount0Out} usdc={profit?.in1.rebalance.amount0In-profit?.in1.rebalance.amount0Out} asItem={true} prefix={`${profit?.in1.rebalance.amount0In-profit?.in1.rebalance.amount0Out < 0n ? 'reb' : 'reb'}`} ethSymbol='ETH' usdcSymbol='ETH in USDC' />
            <ValueEitherOr eth={profit?.in0.rebalance.amount1In-profit?.in0.rebalance.amount1Out} usdc={profit?.in1.rebalance.amount1In-profit?.in1.rebalance.amount1Out} side={'usdc'} asItem={true} prefix={`${profit?.in1.rebalance.amount1In-profit?.in1.rebalance.amount0Out < 0n ? 'reb' : 'reb'}`} ethSymbol='UDC in ETH' usdcSymbol='USDC' />
            {!isWheelNotRebaTrading(profit!) && isWheelBuying(profit!) ? <ValueEitherOr side="usdc" eth={profit?.in0.rebalance.buyBepPrice - percentageOf(profit?.in0.rebalance.buyBepPrice, 500n)!} usdc={profit?.in1.rebalance.buyBepPrice - percentageOf(profit?.in1.rebalance.buyBepPrice, 500n)!} asItem={true} prefix={'BEP '}  ethSymbol='ETH' usdcSymbol='USDC' />
              : (!isWheelNotRebaTrading(profit!) && isWheelSelling(profit!) ? <ValueEitherOr side="usdc" eth={profit?.in0.rebalance.sellBepPrice + percentageOf(profit?.in0.rebalance.sellBepPrice, 500n)!} usdc={profit?.in1.rebalance.sellBepPrice + percentageOf(profit?.in1.rebalance.sellBepPrice, 500n)!} asItem={true} prefix={'BEP '}  ethSymbol='ETH' usdcSymbol='USDC' /> : null)}
            {isWheelNotRebaTrading(profit!) ? <ValueEitherOr eth={profit?.in0.rebalance.bepPrice} side="usdc" usdc={profit?.in1.rebalance.bepPrice} asItem={true} prefix={'BEP '} ethSymbol='ETH' usdcSymbol='USDC' /> : null}
              
              
            </List>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <List>
            <ValueEitherOr eth={profit?.in0.collateral0!} usdc={profit?.in1.collateral0!} asItem={true} prefix={'Collateral '} ethSymbol='ETH' usdcSymbol='ETH in USDC' />
            <ValueEitherOr eth={profit?.in0.collateral1!} usdc={profit?.in1.collateral1!} asItem={true} prefix={'Collateral '} side='usdc' ethSymbol='USDC in ETH' usdcSymbol='USDC' />
              <ValueEitherOr eth={profit?.in0.debt0!} usdc={profit?.in1.debt0!} asItem={true} prefix={'Debt '} ethSymbol='ETH' usdcSymbol='ETH in USDC' />
              <ValueEitherOr eth={profit?.in0.debt1!} usdc={profit?.in1.debt1!} asItem={true} prefix={'Debt '} ethSymbol='USDC in ETH' side='usdc' usdcSymbol='USDC' />
            </List>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
          <Button onClick={() => setViewBars(!viewBars)}>Toggle Chart</Button>
          <Button onClick={switchDisplayedValue}>Toggle Value</Button>
          </Grid>
          {viewBars && <Grid item xs={12} sm={6} md={4}>
          <PieChart
          skipAnimation = {true}
          sx={{
            width: '300',
            height: '300',
          }}
          width={300}
          height={300}
          series={[
            
            
            {
              data: [

                {
                  id: 0,
                  value: showEthValue ? Number(formatUnits(profit.all0!, 18)) : Number(formatUnits(profit?.in1.all0!, 6)),
                  label: 'ETH',
                },
                {
                  id: 1,
                  value: showEthValue ? Number(formatUnits(profit?.in0.active0, 18)) : Number(formatUnits(profit?.in1.active0, 6)),
                  label: 'Active ETH'
                },
                {
                  id: 2,
                  value: showEthValue ? Number(formatUnits(profit?.in0.all1!, 18)) : Number(formatUnits(profit.all1!, 6)),
                  label: 'USDC'
                },
                
                {
                  id: 3,
                  value: showEthValue ? Number(formatUnits(profit?.in0.active1, 18)) : Number(formatUnits(profit?.in1.active1, 6)),
                  label: 'Active USDC'
                }
              ],
              
            },
            
            
          ]}
          xAxis={[

           
          ]}
        ></PieChart>
        </Grid>}
        {viewBars && <Grid item xs={12} sm={6} md={4}>
          <PieChart
          skipAnimation = {true}
          sx={{
            width: '300',
            height: '300',
          }}
          width={300}
          height={300}
          series={[
            
            
            {
              data: [

                {
                  id: 0,
                  value: showEthValue ? Number(formatUnits(profit?.in0.collateral0!, 18)) : Number(formatUnits(profit?.in1.collateral0!, 6)),
                  label: 'cETH',
                },
                {
                  id: 1,
                  value: showEthValue ? Number(formatUnits(profit?.in0.debt0, 18)) : Number(formatUnits(profit?.in1.debt0, 6)),
                  label: 'dETH'
                },
                {
                  id: 2,
                  value: showEthValue ? Number(formatUnits(profit?.in0.collateral1, 18)) : Number(formatUnits(profit?.in1.collateral1, 6)),
                  label: 'cUSDC'
                },
                
                {
                  id: 3,
                  value: showEthValue ? Number(formatUnits(profit?.in0.debt1, 18)) : Number(formatUnits(profit?.in1.debt1, 6)),
                  label: 'dUSDC'
                }
              ],
              
            },
            
            
          ]}
          
        ></PieChart>
            </Grid>}
        </Grid>


      
    </React.Fragment>
  );
}

export default QuickView;
