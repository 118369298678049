
export function toLowerTickBound(tick: bigint, spacing: bigint = 10n): bigint {

  const mod: bigint = BigInt(tick) % spacing;
  tick = BigInt(tick);
  if (tick < 0n) {
    if (mod === 0n) {
      return BigInt(tick - mod);
    }
    return BigInt(tick - mod - spacing);
  } else {
    return BigInt(tick - mod);
  }
}
