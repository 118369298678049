import { tickState, toLowerTickBound } from "../actions";
import { MarketSwapEventArgs, onMarketSwap } from "../listeners/market";
import { TickState } from "../types";

export type TickGroupFilter = (tick: bigint) => boolean;
export type TickListenerEvent = {
  listenerId: string;
  rawTick: bigint;
  tick: bigint;
  state: TickState;
  lastMatchedAt: number;
  lastUnmatchedAt: number;
  isMatched: boolean;
  isActive: boolean;
  hasLiquidity: boolean;
  lastTickMatched: bigint;
  distanceToLastMatched: bigint;
}

export type TickListener = (event: TickListenerEvent) => void;

export type TickListenerDetach = () => void;
export type TickListenerInfo = {
  filter: TickGroupFilter;
  onMatch: TickListener;
  onUnmatch: TickListener;
  onDetach: TickListenerDetach;
  lastMatchedAt: number;
  lastUnmatchedAt: number;
  lastTickMatched: bigint;
}

export type TickListenerAttachArgs = {
  listenerId: string;
  tickGroupFilter: TickGroupFilter;
  onMatch?: TickListener;
  onUnmatch?: TickListener;
  onDetach?: TickListenerDetach;
}

export const ticksWatcher = (() => {
  const ticks: Record<number, TickState> = {};
  const lastTickMatched: Record<string, bigint> = {};
  const listeners: Record<string, TickListenerInfo> = {};
  const listenersIds: Set<string> = new Set();
  const noEventListener: TickListener = (_event: TickListenerEvent) => { };
  let lastRefTick: bigint = 0n;
  let lastRefTickTime: number = 0;
  const trackMarket = async (event: MarketSwapEventArgs) => {
    const refTick = toLowerTickBound(event.tick);
    const refTickNumber = Number(refTick);
    if (!ticks[refTickNumber]) {
      const nextState = await tickState(refTick);
      ticks[refTickNumber] = nextState;
    }

    if (lastRefTick != refTick) {
      if (lastRefTick == 0n) {
        lastRefTick = refTick;
        lastRefTickTime = Date.now();

      }
      ticks[Number(lastRefTick)] = await tickState(lastRefTick);


      // onUnmatch for all listeners
      let xTick = lastRefTick;
      const direction = refTick > lastRefTick ? 1n : -1n;
      const continuetionCheck = () => {
        return direction > 0 ? xTick < refTick : xTick > refTick;
      }

      while (continuetionCheck()) {
        for (const listenerId of listenersIds) {
          const listener = listeners[listenerId];

          listener.onUnmatch({
            listenerId,
            rawTick: xTick,
            tick: xTick,
            state: ticks[Number(xTick)],
            lastMatchedAt: listener.lastMatchedAt,
            lastUnmatchedAt: listener.lastUnmatchedAt,
            isMatched: false,
            isActive: ticks[Number(xTick)].spacesToMiddle === 0n,
            hasLiquidity: ticks[Number(xTick)].liquidity > 0n,
            lastTickMatched: listener.lastTickMatched,
            distanceToLastMatched: xTick - listener.lastTickMatched
          });

        }
        xTick += direction * 10n;
      }

      lastRefTick = refTick;
    } else {
      if (Date.now() - lastRefTickTime > 1000) {
        lastRefTickTime = Date.now();
        ticks[refTickNumber] = await tickState(refTick);
      }
    }
    for (const listenerId of listenersIds) {
      const listener = listeners[listenerId];
      if (listener.filter(refTick)) {
        if (listener.lastMatchedAt == 0) {
          listener.lastMatchedAt = Date.now();

        }
        if (listener.lastTickMatched == 0n) {
          listener.lastTickMatched = refTick;
        }
        listener.onMatch({
          listenerId,
          rawTick: event.tick,
          tick: refTick,
          state: ticks[refTickNumber],
          lastMatchedAt: listener.lastMatchedAt,
          lastUnmatchedAt: listener.lastUnmatchedAt,
          isMatched: true,
          isActive: ticks[refTickNumber].spacesToMiddle === 0n,
          hasLiquidity: ticks[refTickNumber].liquidity > 0n,
          lastTickMatched: listener.lastTickMatched,
          distanceToLastMatched: event.tick - listener.lastTickMatched
        });
        listener.lastTickMatched = refTick;
      } else {
        if (listener.lastMatchedAt > listener.lastUnmatchedAt) {
          listener.lastUnmatchedAt = Date.now();


        }
        listener.onUnmatch({
          listenerId,
          rawTick: event.tick,
          tick: event.tick,
          state: ticks[refTickNumber],
          lastMatchedAt: listener.lastMatchedAt,
          lastUnmatchedAt: listener.lastUnmatchedAt,
          isMatched: false,
          isActive: ticks[refTickNumber].spacesToMiddle === 0n,
          hasLiquidity: ticks[refTickNumber].liquidity > 0n,
          lastTickMatched: listener.lastTickMatched,
          distanceToLastMatched: event.tick - listener.lastTickMatched
        });
      }
    }
  };

  onMarketSwap.attach(trackMarket);



  return {
    getTick: (tick: bigint) => ticks[Number(toLowerTickBound(tick))] || {
      tick: 0n,
      tokenId: 0n,
      active0: 0n,
      active1: 0n,
      liquidity: 0n,
      spacesToMiddle: 0n,
      rawTicksToMiddle: 0n,
      tickSpacing: 0n,
      tickSpacingHalf: 0n,
      tickSpacingQuarter: 0n,
      tickSpacingEighth: 0n,
      tickSpacingSixteenth: 0n,
      amount0In: 0n,
      amount0Out: 0n,
      amount1In: 0n,
      amount1Out: 0n,
      priceIn: 0n,
      priceOut: 0n,
      feeGrowthInside0LastX128: 0n,
      feeGrowthInside1LastX128: 0n,

    },
    fetchTick: async (tick: bigint) => {
      const refTick = toLowerTickBound(tick);
      const refTickNumber = Number(refTick);
      ticks[refTickNumber] = await tickState(refTick);
      return ticks[refTickNumber] || {
        tick: 0n,
        tokenId: 0n,
        active0: 0n,
        active1: 0n,
        liquidity: 0n,
        spacesToMiddle: 0n,
        rawTicksToMiddle: 0n,
        tickSpacing: 0n,
        tickSpacingHalf: 0n,
        tickSpacingQuarter: 0n,
        tickSpacingEighth: 0n,
        tickSpacingSixteenth: 0n,
        amount0In: 0n,
        amount0Out: 0n,
        amount1In: 0n,
        amount1Out: 0n,
        priceIn: 0n,
        priceOut: 0n,
        feeGrowthInside0LastX128: 0n,
        feeGrowthInside1LastX128: 0n,
      };
    },

    attach: (args: TickListenerAttachArgs) => {
      if (listenersIds.has(args.listenerId)) {
        listenersIds.delete(args.listenerId);
        try {
          listeners[args.listenerId].onDetach();
        } catch (e) {
          //console.error(e);
        } finally {
          delete listeners[args.listenerId];
        }
      }
      listenersIds.add(args.listenerId);
      listeners[args.listenerId] = {
        filter: args.tickGroupFilter,
        onMatch: args.onMatch ?? noEventListener,
        onUnmatch: args.onUnmatch ?? noEventListener,
        onDetach: args.onDetach ?? (() => { }),
        lastMatchedAt: 0,
        lastUnmatchedAt: 0,
        lastTickMatched: 0n,
      }
    },
    detach: (listenerId: string) => {
      if (!listenersIds.has(listenerId)) {
        return;
      }
      listenersIds.delete(listenerId);
      try {
        listeners[listenerId].onDetach();
      } catch (e) {
        //console.error(e);
      } finally {

        delete listeners[listenerId];
      }

    }
  }
})();

